<!--
  Element UI el-form-item component tweak
  - added automatic :prop computation from nested input's v-model
  - basic support for loops (non-nested)
-->
<template>
  <el-form-item-legacy v-bind="$attrs" v-on="$listeners" :prop="cProp" :key="cProp" ref="formItem">
    <!-- custom label with support for "help" -->
    <template v-if="$slots.label || $slots.help || $attrs.help" slot="label">
      <slot v-if="$slots.label" name="label"></slot>
      <template v-else>{{$attrs.label}}</template>

      <ls-help v-if="$slots.help || $attrs.help">
        <template v-if="$attrs.help">
          <template v-if="typeof $attrs.help === 'string'">{{$attrs.help}}</template>
          <VNodes v-else :node="$attrs.help"/>
        </template>
        <slot v-else name="help"></slot>
      </ls-help>
    </template>

    <slot></slot>
  </el-form-item-legacy>
</template>
<style lang="scss">
.el-form-item_popper {
  max-width: 90%;
}

@media (min-width: 600px) {
  .el-form-item_popper {
    max-width: 500px;
  }
}
</style>
<script>
  export default {
    inject: {
      lsFormItem: {
        default: null
      }
    },
    props: {
      //manual prop settings
      prop: String,

      //settings for loops
      root: String, //root property of loop, eg doc.sellers
      index: Number, //loop index
      lsLoop: { //remove .model from ls-loop
        type: Boolean,
        default: undefined
      }
    },

    //fix for passing "help" as VNode attribute (used in combination with ls-form-lang where we can not pass slot easily)
    components: {
      VNodes: {
        functional: true,
        render: (_, ctx) => ctx.props.node
      }
    },
    data () {
      return {
        loaded: false
      }
    },

    computed: {
      //mirroring defaults
      validateState() { return this.$refs?.formItem?.validateState },
      validateMessage() { return this.$refs?.formItem?.validateMessage },

      cRoot() {
        return this.root ?? this.lsFormItem?.root;
      },
      cIndex() {
        return this.index ?? this.lsFormItem?.index;
      },
      cLsLoop() {
        return this.lsLoop ?? this.lsFormItem?.lsLoop;
      },

      cProp() {
        this.loaded;

        if(this.prop || !this.$attrs.rules || (Array.isArray(this.$attrs.rules) && this.$attrs.rules.length === 0))
          return this.prop;

        if(!this.$refs?.formItem)
          return undefined;

        try {
          var vModel;
          //v-model text representation
          vModel = this._findInput()?.$vnode?.data?.model?.expression;
          if(!vModel) {
            console.log("el-form-item children not found for:", this.$refs?.formItem?.$el);
            return undefined;
          }

          vModel = this._removeRoot(vModel);

          //remove item.model.xxx if inside ls-loop
          if(this.cLsLoop) {
            vModel = this._removeModel(vModel);
          }

          //different root and index provided - we are inside loop
          if(this.cRoot && this.cIndex !== undefined)
            vModel = this._removeRoot(this.cRoot) + '.' + this.cIndex + (vModel ? '.' + vModel : '');

          if(!vModel) {
            console.log("el-form-item children not found for:", this.$refs?.formItem?.$el);
          }

          return vModel;
        }
        catch(e) {
          console.log("el-form-item children not found for:", this.$refs?.formItem?.$el);
          console.log(e);
          this.$sentry.captureException(e);
          return undefined;
        }
      }
    },

    mounted() {
      //force computed recalculation when mounted
      this.loaded = true;
    },

    methods: {
      //mirroring defaults
      validate() { return this.$refs?.formItem?.validate() },
      clearValidate() { return this.$refs?.formItem?.clearValidate() },
      resetField() { return this.$refs?.formItem?.resetField() },

      //find nested input component
      _findInput() {
        var allowed = [
          //ELEMENT UI
          'ElInput',
          'ElInputNumber',
          'ElAutocomplete',
          'ElSelect',
          'ElCascader',
          'ElRadio',
          'ElRadioButton',
          'ElRadioGroup',
          'ElSwitch',
          'ElCheckboxGroup',
          'ElCheckboxButton',
          'ElCheckbox',
          'ElSlider',
          'ElUpload',
          'ElRate',
          'ElTransfer',
          'ElDatePicker',
          'ElTimePicker',
          'ElTimeSelect',

          //LEGAL SYSTEMS
          'LsSelect',
          'LsAutocomplete',
          'LsContacts',
          'LsCountries',
          'LsUpload',
        ];

        return this.$refs.formItem.$children?.find(x => allowed.includes(x.$options?.name))
      },

      //remove root element 'doc.xxx' or 'item.xxx'
      _removeRoot(x) {
        let i = x.indexOf(".");

        if(i < 0)
          return '';

        return x.substring(i + 1);
      },

      //remove 'model.xxx' if inside ls-loop ('item.' was already removed)
      _removeModel(x) {
        return x.replace(/^(model\.?)/, "");
      }
    },

  };

</script>
