<template>
<el-form ref="form" status-icon :model="doc" label-position="top" v-cloak v-show="vueLoaded" v-loading="vueLoading" class="signature-create">
  <div class="h1" style="text-align: center"><template v-if="bulk.enabled">{{ $t('ls.lsSignature.bulkSignature') }} </template>{{ $t('ls.lsSignature.electronicSignature') }}</div>

  <el-steps :active="doc.active" finish-status="success" align-center>
    <el-step :title="$t('ls.lsSignature.uploadDocuments')"></el-step>
    <el-step :title="$t('ls.lsSignature.signingParties')"></el-step>
    <el-step :title="$t('ls.lsSignature.signaturePlacement')"></el-step>
    <el-step :title="$t('ls.lsSignature.submission')"></el-step>
  </el-steps>

  <!-- file upload -->
  <div v-if="doc.active == 0" v-loading="s.fileUpload === false">
    <el-card :class="'signature-step-1 box-card' + (bulk.enabled ? ' settings-card orange-card' : '')">
      <div slot="header" class="clearfix">
        <span class="h3">{{ $t('ls.lsSignature.uploadDocumentsFor') }} <template v-if="bulk.enabled">{{ $t('ls.lsSignature.forBulk') }} </template>{{ $t('ls.lsSignature.signature') }}</span>
      </div>

      <!-- BULK SIGNATURE ONLY -->
      <el-form-item :label="$t('ls.lsSignature.bulkSignatureType')" v-if="bulk.enabled">
        <el-radio-group v-model="bulk.zip">
          <el-radio-button :label="false">{{ $t('ls.lsSignature.singleFile') }}</el-radio-button>
          <el-radio-button :label="true">{{ $t('ls.lsSignature.multipleFiles') }}</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <!-- BULK SIGNATURE ONLY -->

      <template v-if="!bulk.enabled || !bulk.zip">
        <div style="font-size: 1.1em; margin-bottom: 20px" class="help-text">
          {{ $t('ls.lsSignature.uploadInstructions') }}
        </div>

        <draggable v-model="fileList" handle=".el-icon-document">
          <div class="fileList" v-for="(file, index) in fileList" :key="'f'+index">
            <div class="icon">
              <i v-if="file.status != 'success'" class="el-icon-loading" />
              <i v-else class="el-icon-document" style="cursor: grab;" />
            </div>
            <div class="filename">
              <el-input v-if="!index && file.titleEditing" v-model="file.name" :placeholder="$t('ls.lsSignature.fileName')">
                <el-button size="mini" slot="append" @click="file.titleEditing = false" icon="el-icon-check"></el-button>
              </el-input>
              <template v-else>{{ file.name }}</template>
            </div>
            <div>.pdf</div>
            <div class="fileButtons">
              <template v-if="!index">
                <el-button type="text" @click="handleEdit(index)"><i class="el-icon-edit"></i></el-button>
              </template>
              <el-button type="text" @click="handleDelete(index)"><i class="el-icon-delete"></i></el-button>
            </div>
          </div>
        </draggable>
        <el-upload
          class="docx-uploader"
          drag
          :multiple="true"
          :show-file-list="false"
          accept="
            .pdf,
            .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document
            .xls,xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
          "
          :action="apiEndpoint+'/podpisy/upload'"
          :http-request="handleHttp"
          :headers="uploadHeaders"
          :before-upload="beforeUpload"
          :on-success="handleSuccess"
          :on-error="handleError"
          :on-progress="handleProgress"
          :file-list="fileList">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            {{ $t('ls.lsSignature.dragFileHere') }} <em>{{ $t('ls.lsSignature.clickToUpload') }}</em><br>
            <small><i>{{ $t('ls.lsSignature.supportedFiles') }}</i></small><br>
            <small>{{ $t('ls.lsSignature.fileSizeLimit') }}</small>
          </div>
        </el-upload>
        <center><small>{{ $t('ls.lsSignature.fileCompressionAdvice') }}
          <a target="_blank" href="https://www.adobe.com/cz/acrobat/online/compress-pdf.html">&gt; {{ $t('ls.lsSignature.here') }} &lt;</a>
        </small></center>
        <div style="text-align: right;" v-if="bulkEnabled && !bulk.enabled">
          <el-button type="text" @click="bulk.enabled=true">{{ $t('ls.lsSignature.bulkDispatch') }}</el-button>
        </div>
      </template>
      <template v-else>
        <div style="font-size: 1.1em; margin-bottom: 20px">
          {{ $t('ls.lsSignature.bulkUploadInstructions') }}
        </div>

        <el-upload
          accept=".zip"
          :action="hostname+'/proculus-api/podpisy/uploadZip'"
          :multiple="false"
          :limit="1"
          :on-success="handleSuccessZip"
          :on-error="handleError">
          <el-button size="small" type="primary">{{ $t('ls.lsSignature.uploadZipArchive') }}</el-button>
        </el-upload>

      </template>
    </el-card>
  </div>

  <!-- subjects setting -->
  <div v-if="doc.active == 1">
    <!-- actual person settings -->
    <el-card class="box-card person" v-if="bulk.active == 0">
      <div slot="header" class="clearfix">
        <span class="h3">{{ $t('ls.lsSignature.signingParties') }}</span>
      </div>

      <el-alert type="info">
        <template slot="title">
          {{ $t('ls.lsSignature.signingPartiesInfo') }} <template v-if="bulk.enabled">{{ $t('ls.lsSignature.variableSignatorySetting') }}</template>
        </template>
      </el-alert>
      <br>

      <el-form-item>
        <el-checkbox v-model="doc.order" @change="localFirst">
          {{ $t('ls.lsSignature.signingOrder') }}
        </el-checkbox>
          <el-popover trigger="hover" width="300">
            <div style="text-align:justify; word-break: keep-all;">
              {{ $t('ls.lsSignature.signingOrderInfo') }}
            </div>
            <i class="el-icon-question" slot="reference"></i>
          </el-popover>
      </el-form-item>
      <div :key="'sk'+subjChangeKey">
      <el-card class="box-card person-card" v-for="(subject, index) in doc.subjects" :key="index" shadow="never">
        <div slot="header" class="clearfix strana-header">
          <span class="h4">
            <template v-if="doc.order && doc.subjects.length>1 && isRemote(subject)">
              <el-button class="reorder" type="text" icon="el-icon-bottom" @click="moveItem(doc.subjects, index, index+1)"></el-button><el-button class="reorder" type="text" icon="el-icon-top" @click="moveItem(doc.subjects, index, index-1)"></el-button>
            </template>
            {{ $t('ls.lsSignature.signatory') }}
            <template v-if="doc.order && doc.subjects.length>1">
              {{ $t('ls.lsSignature.numberedSignatory', { number: index + 1 }) }}
            </template>
            <el-radio-group class="radio-card hidden-xs-only" v-model="subject.signature.subject" size="mini" @change="subjectChanged(subject)">
              <el-radio-button label="me">{{ $t('ls.lsSignature.me') }}</el-radio-button>
              <el-radio-button label="else">{{ $t('ls.lsSignature.someoneElse') }}</el-radio-button>
              <el-radio-button v-if="bulk.enabled" label="variable">{{ $t('ls.lsSignature.variable') }}</el-radio-button>
            </el-radio-group>
          </span>
          <el-tooltip v-if="doc.subjects.length>1" class="item" effect="dark" :content="$t('ls.lsSignature.deleteParty')" placement="left">
            <el-button class="remove-person-button" type="text" icon="el-icon-close" @click="removeArrayItem(doc.subjects, index)"></el-button>
          </el-tooltip>
        </div>

        <el-row class="hidden-sm-and-up">
          <el-col :span="24">
            <el-form-item :label="$t('ls.lsSignature.signatory')">
              <el-radio-group v-model="subject.signature.subject" @change="subjectChanged(subject)">
                <el-radio-button label="me">{{ $t('ls.lsSignature.me') }}</el-radio-button>
                <el-radio-button label="else">{{ $t('ls.lsSignature.someoneElse') }}</el-radio-button>
                <el-radio-button v-if="bulk.enabled" label="variable">{{ $t('ls.lsSignature.variable') }}</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <template v-if="subject.signature.subject != 'variable'">
          <el-form-item v-if="contacts && subject.signature.type != 'local' && subject.signature.subject != 'me'" :rules="[rl.required]" :prop="'subjects.'+index+'.contact_id'" :key="subject.contact_id">
            <ContactCard
              :value="subject"
              :additional-columns="['phone', 'email']"
              :change-internal="contactToSignatoryMapper"
              :change-external="signatoryToContactMapper"
              :collision-handling="false"
            />
          </el-form-item>

          <template v-if="subject.signature.type == 'local' || subject.signature.subject == 'me' || !contacts || subject.contact_id">
            <el-row>
              <el-col :sm="10" :md="8">
                <el-form-item :label="$t('ls.lsSignature.fullName')" :prop="'subjects.'+index+'.signature.name'" :rules="[rl.required]">
                  <template v-if="subject.signature.subject == 'me'">
                    <el-input v-model="subject.signature.name" :disabled="true" :placeholder="$t('ls.lsSignature.fullName')"></el-input>
                  </template>
                  <template v-else>
                    <template v-if="!contacts && suggestions">
                      <ls-autocomplete2
                        v-model="subject.signature.name"
                        suggestions="signature_users"
                        storage="combined"
                        :placeholder="$t('ls.lsSignature.fullName')"
                        :multi="{
                          email: {
                            validators: [rl.required, rl.email],
                            placeholder: 'E-mail'
                          },
                          phone: {
                            validators: [rl.phone],
                            placeholder: 'Telefon'
                          }
                        }"
                        @select="subject.signature.email = $event.email; subject.signature.phone = $event.phone;"
                      />
                    </template>
                    <template v-else-if="injected.length">
                      <el-autocomplete
                        v-model="subject.signature.name"
                        :placeholder="$t('ls.lsSignature.fullName')"
                        :fetch-suggestions="querySearch(injected, 'name')"
                        value-key="name"
                        @select="subject.signature.email = $event.email; subject.signature.phone = $event.phone;"
                      />
                    </template>
                    <template v-else>
                      <el-input
                        v-model="subject.signature.name"
                        :placeholder="$t('ls.lsSignature.fullName')"
                      />
                    </template>
                  </template>
                </el-form-item>
              </el-col>
              <el-col :sm="8">
                <ls-input-email
                  v-model="subject.signature.email"
                  :prop="'subjects.'+index+'.signature.email'"
                  required
                  :disabled="subject.signature.subject == 'me'"/>
              </el-col>
              <el-col :sm="6" :md="8">
                <el-form-item :label="$t('ls.lsSignature.signatureMethod')" :prop="'subjects.'+index+'.signature.type'">
                  <el-select v-model="subject.signature.type" :placeholder="$t('ls.lsSignature.choose')" @change="signatureTypeChange(subject)">
                    <el-option :label="$t('ls.lsSignature.local')" value="local"></el-option>
                    <el-option :label="$t('ls.lsSignature.remote')" value="remote"></el-option>
                    <el-option v-if="doc.order" :label="$t('ls.lsSignature.remoteApprovalOnly')" value="remote-review"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <div v-if="subject.signature.type == 'local'">
              <ls-signature 
                :key="subject.signature.subject"
                :subject="subject.signature.name" 
                v-model="subject.signature.sign" 
                :saved="subject.signature.subject == 'me' ? user.sign : null" 
                :default-input-method="$context.signature ? $context.signature.defaultInputMethod : null"
                required 
                :prop="'subjects.'+index+'.signature.sign'"
              />
            </div>
            <div v-if="isRemote(subject)">
              <el-form-item v-if="subject.signature.type == 'remote-review'">
                <el-switch v-model="subject.signature.reviewEmail" :active-text="$t('ls.lsSignature.sendSignedOriginal')"></el-switch>
              </el-form-item>
              <el-row>
                <el-col :md="8">
                  <el-switch v-model="subject.signature.verif" :active-text="$t('ls.lsSignature.twoFactorAuth')"></el-switch>

                  <el-popover trigger="hover" width="300">
                    <div style="text-align:justify; word-break: keep-all;">
                      {{ $t('ls.lsSignature.twoFactorAuthInfo') }}
                    </div>
                    <i class="el-icon-question" slot="reference"></i>
                  </el-popover>
                </el-col>
                <el-col :md="8">
                  <el-switch v-model="subject.signature.kyc" :active-text="$t('ls.lsSignature.kycVerification')"></el-switch>

                  <el-popover trigger="hover" width="300">
                    <div style="text-align:justify; word-break: keep-all;">
                      {{ $t('ls.lsSignature.kycVerificationInfo') }}
                    </div>
                    <i class="el-icon-question" slot="reference"></i>
                  </el-popover>
                </el-col>
                <el-col :md="8">
                  <el-switch :disabled="!bankid" v-model="subject.signature.bankid" :active-text="$t('ls.lsSignature.bankIdSign')"></el-switch>

                  <el-popover trigger="hover" width="300">
                    <div style="text-align:justify; word-break: keep-all;">
                      {{ $t('ls.lsSignature.bankIdSignInfo') }}
                    </div>
                    <span slot="reference">
                      <i class="el-icon-question"></i>
                      <i class="el-icon-bank-card"></i>
                    </span>
                  </el-popover>
                </el-col>
              </el-row>

              <!-- 2FA settings -->
              <div v-if="subject.signature.verif" style="margin-top: 10px">
                <span class="h4" style="margin-bottom: 10px">{{ $t('ls.lsSignature.twoFactorAuth') }}</span>
                <el-row>
                  <el-col :sm="12" :md="8">
                    <el-form-item :label="$t('ls.lsSignature.password')" :prop="'subjects.'+index+'.signature.code'" :rules="[
                      rl.required,
                      { min: 4, max: 8, message: $t('ls.lsSignature.passwordLength') }
                    ]">
                      <ls-copy editable v-model="subject.signature.code"></ls-copy>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="8">
                    <ls-input-phone
                      v-model="subject.signature.phone"
                      :prop="'subjects.'+index+'.signature.phone'"
                      :label="$t('ls.lsSignature.phoneForPassword')"
                      :disabled="!subject.signature.phoneEnabled"
                      :required="subject.signature.phoneEnabled"
                    >
                      <el-checkbox slot="prepend" v-model="subject.signature.phoneEnabled" @change="clearPhone(subject)"></el-checkbox>
                    </ls-input-phone>
                  </el-col>
                </el-row>
              </div>

              <!-- KYC settings -->
              <div v-if="subject.signature.kyc" style="margin-top: 10px">
                <span class="h4">{{ $t('ls.lsSignature.kycVerification') }}</span>
                <el-row style="margin-top: 10px">
                  <el-col :sm="12" :md="8">
                    <el-switch v-model="subject.signature.kycsettings.micropayment.enabled" :active-text="$t('ls.lsSignature.micropayment')"></el-switch>
                  </el-col>
                  <el-col :sm="12" :md="8">
                    <el-switch v-model="subject.signature.kycsettings.require_additional_document" :active-text="$t('ls.lsSignature.requireAdditionalDocument')"></el-switch>
                    <el-popover trigger="hover" width="300">
                      <div style="text-align:justify; word-break: keep-all;">
                        {{ $t('ls.lsSignature.requireAdditionalDocumentInfo') }}
                      </div>
                      <span slot="reference">
                        <i class="el-icon-question"></i>
                      </span>
                    </el-popover>
                  </el-col>
                </el-row>
                <template v-if="subject.signature.kycsettings.micropayment.enabled">
                  <el-row>
                    <el-col :sm="12" :md="8">
                      <el-form-item :label="$t('ls.lsSignature.accountNumber')" :prop="'subjects.'+index+'.signature.kycsettings.micropayment.cu'" :rules="[rl.cu, rl.required]">
                        <el-input v-model="subject.signature.kycsettings.micropayment.cu" :placeholder="$t('ls.lsSignature.accountNumber')"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="12" :md="8">
                      <el-form-item :label="$t('ls.lsSignature.variableSymbol')" :prop="'subjects.'+index+'.signature.kycsettings.micropayment.vs'" :rules="[rl.numberString]">
                        <el-input maxlength="10" show-word-limit v-model="subject.signature.kycsettings.micropayment.vs" :placeholder="$t('ls.lsSignature.variableSymbol')"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-form-item :label="$t('ls.lsSignature.paymentNote')">
                    <el-input maxlength="140" show-word-limit v-model="subject.signature.kycsettings.micropayment.comment" :placeholder="$t('ls.lsSignature.paymentNote')"></el-input>
                  </el-form-item>
                </template>
              </div>
            </div>
          </template>
        </template>
        <template v-else>
          {{ $t('ls.lsSignature.variableSubject') }}
        </template>
      </el-card>
      </div>

      <el-button style="margin-top:10px" type="danger" @click="addArrayItem(doc.subjects, getEmptySubject())">
        <i class="el-icon-plus"></i> {{ $t('ls.lsSignature.addSignatory') }}
      </el-button>
    </el-card>

    <!-- tab for bulk subject settings -->
    <el-card class="box-card" v-if="bulk.enabled && bulk.active == 1" v-loading="csvLoading">
      <div slot="header" class="clearfix">
        <span class="h3">{{ $t('ls.lsSignature.bulkData') }}</span>
      </div>
      <div style="font-size: 1.1em">{{ $t('ls.lsSignature.bulkDataInstructions') }} <el-button type="text" @click="downloadTemplate">{{ $t('ls.lsSignature.downloadCsvTemplate') }}</el-button></div><br>

      <el-row>
        <el-col :span="24">
          <el-form-item>
            <el-upload
              :multiple="false"
              :limit="1"
              accept=".csv"
              action="#"
              :show-file-list="false"
              :http-request="loadCSV"
              :file-list="[]">
              <el-button size="small" type="primary">{{ $t('ls.lsSignature.uploadCsvFile') }}</el-button>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form ref="form2" :model="bulk">
        <el-table
          :data="bulk.myData"
          border
          :resizable="false"
          max-height="500"
          style="width: 100%">
          <el-table-column v-for="(subj, ix) in subjectsVariable" :key="'c'+ix" :label="$t('ls.lsSignature.variableParty', { number: ix + 1 })">
            <el-table-column :label="$t('ls.lsSignature.name')">
              <template slot-scope="scope">
                <el-form-item :prop="'data.'+getIndex(scope.$index)+'.subjects.'+ix+'.signature.name'" :rules="[rl.required]">
                  <el-input v-model="scope.row.subjects[ix].signature.name"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column :label="$t('ls.lsSignature.email')">
              <template slot-scope="scope">
                <el-form-item :prop="'data.'+getIndex(scope.$index)+'.subjects.'+ix+'.signature.email'" :rules="[rl.email, rl.required]">
                  <el-input v-model="scope.row.subjects[ix].signature.email"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column :label="$t('ls.lsSignature.2fa')" align="center" header-align="left" width="50">
              <template slot-scope="scope">
                <el-form-item>
                  <el-checkbox v-model="scope.row.subjects[ix].signature.verif"></el-checkbox>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column>
              <template slot="header">{{ $t('ls.lsSignature.phone') }} <el-popover trigger="hover" width="300">
                  <div style="text-align:justify; word-break: keep-all;">
                    {{ $t('ls.lsSignature.phoneFor2fa') }}
                  </div>
                  <i class="el-icon-question" slot="reference"></i>
                </el-popover>
              </template>
              <template slot-scope="scope">
                <el-form-item :prop="'data.'+getIndex(scope.$index)+'.subjects.'+ix+'.signature.phone'" :rules="[scope.row.subjects[ix].signature.verif ? rl.phone : {}]">
                  <el-input v-model="scope.row.subjects[ix].signature.phone" :disabled="!scope.row.subjects[ix].signature.verif"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column :label="$t('ls.lsSignature.password')">
              <template slot-scope="scope">
                <el-form-item :prop="'data.'+getIndex(scope.$index)+'.subjects.'+ix+'.signature.code'" :rules="[scope.row.subjects[ix].signature.verif ? rl.required : {}]">
                  <el-input v-model="scope.row.subjects[ix].signature.code" :disabled="!scope.row.subjects[ix].signature.verif"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column v-if="bulk.zip">
            <template slot="header">{{ $t('ls.lsSignature.file') }} <el-popover trigger="hover" width="300">
                <div style="text-align:justify; word-break: keep-all;">
                  {{ $t('ls.lsSignature.zipFileInfo') }}
                </div>
                <i class="el-icon-question" slot="reference"></i>
              </el-popover>
            </template>
            <template slot-scope="scope">
              <el-form-item :prop="'data.'+getIndex(scope.$index)+'.file'" :rules="[rl.required]">
                <el-select v-model="scope.row.file">
                  <el-option
                    v-for="(item, index) in bulk.zipList"
                    :label="item"
                    :key="'b'+index"
                    :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">{{ $t('ls.lsSignature.pdfLock') }} <el-popover trigger="hover" width="300">
                <div style="text-align:justify; word-break: keep-all;">
                  {{ $t('ls.lsSignature.pdfLockInfo') }}
                </div>
                <i class="el-icon-question" slot="reference"></i>
              </el-popover>
            </template>
            <template slot-scope="scope">
              <el-form-item v-if="bankIds.length === 0">
                <el-input v-model="scope.row.pass" show-password autocomplete="off"></el-input>
              </el-form-item>
              <template v-else>
                {{ $t('ls.lsSignature.pdfLockNotAvailable') }}
              </template>
            </template>
          </el-table-column>
          <el-table-column width="80">
            <template slot-scope="scope">
              <el-button plain type="danger" @click="removeItem(bulk.data, scope.$index)"><i class="fas fa-trash-alt"></i></el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form>

      <el-pagination
        @current-change="handleCurrentChange($event, bulk.data)"
        :current-page.sync="bulk.currentPage"
        :page-size="20"
        layout="total, prev, pager, next, jumper"
        :total="bulk.data.length">
      </el-pagination>

      <el-button plain type="primary" @click="addItem"><i class="el-icon-plus"></i> {{ $t('ls.lsSignature.addItem') }}</el-button>
    </el-card>
  </div>

  <!-- signature placing -->
  <div v-if="doc.active == 2">
    <el-card class="box-card" style="overflow: inherit">
      <div slot="header" class="clearfix">
        <span class="h3">{{ $t('ls.lsSignature.signaturePlacement') }}</span>
      </div>
      <div style="font-size: 1.1em">{{ $t('ls.lsSignature.dragSignatureField') }}</div>
      <SignComposer
        v-model="doc.coordinates"
        :pdf="doc.file"
        :subjects="doc.subjects.map(x=>x.signature)"
      />
    </el-card>
  </div>

  <!-- settings tab -->
  <div v-if="doc.active == 3" v-loading="submitLoading" :element-loading-text="$t('ls.lsSignature.sending')+'...'">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="h3">{{ $t('ls.lsSignature.settings') }}</span>
      </div>

      <template v-if="settingsVisible">
        <el-row>
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item :label="$t('ls.lsSignature.reminderEveryXDays')">
              <el-input-number :min="0" v-model="doc.resend" :placeholder="$t('ls.lsSignature.days')"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item :label="$t('ls.lsSignature.linkExpirationDays')">
              <el-input-number :min="1" :max="120" v-model="doc.expiration" :placeholder="$t('ls.lsSignature.days')"><template slot="append">{{ $t('ls.lsSignature.days') }}</template></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item :label="$t('ls.lsSignature.signingNotification')">
              <el-checkbox border v-model="doc.notifications">{{ $t('ls.lsSignature.signingNotification') }}</el-checkbox>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item :label="$t('ls.lsSignature.linkLanguage')">
              <el-select v-model="doc.language" :placeholder="$t('ls.lsSignature.select')">
                <el-option :label="$t('ls.lsSignature.language.cz')" value="cz"></el-option>
                <el-option :label="$t('ls.lsSignature.language.en')" value="en"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </template>
      <el-row v-if="s.fileLock && !bulk.enabled && bankIds.length === 0">
        <el-col :xs="24" :sm="12" :md="8">
          <el-form-item>
            <template slot="label">{{ $t('ls.lsSignature.pdfLock') }} <el-popover trigger="hover" width="300">
                <div style="text-align:justify; word-break: keep-all;">
                  {{ $t('ls.lsSignature.pdfLockInfo') }}
                </div>
                <i class="el-icon-question" slot="reference"></i>
              </el-popover>
            </template>
            <el-input :disabled="doc.pass === null" show-password v-model="doc.pass" :placeholder="$t('ls.lsSignature.pdfPassword')" autocomplete="off">
              <el-checkbox slot="prepend" :value="doc.pass !== null" @input="doc.pass = $event ? '' : null" />
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- TAGS feature -->
      <el-row v-if="$context.access == 'internal'">
        <el-col :md="12">
          <el-form-item>
            <template slot="label">
              {{ $t('ls.lsSignature.tags') }}
              <el-popover trigger="hover" width="300">
                <div style="text-align:justify; word-break: keep-all;">
                  {{ $t('ls.lsSignature.tagsInfo') }}
                </div>
                <i class="el-icon-question" slot="reference"></i>
              </el-popover>
            </template>
            <ls-select
              v-model="doc.tags"
              suggestions="signature_tags"
              multiple
              storage="combined"
              :placeholder="$t('ls.lsSignature.addTags')"
            ></ls-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-card>

    <el-card class="box-card" v-if="s.cc" key="cc-card">
      <div slot="header" class="clearfix">
        <span class="h3">{{ $t('ls.lsSignature.sendCopy') }}</span>
      </div>

      <el-alert
        type="warning"
        :closable="false"
        show-icon>
        <div slot="title" style="word-break: break-word; text-align: justify;">
          {{ $t('ls.lsSignature.sendCopyInfo') }}
        </div>
      </el-alert>

      <el-row>
        <el-col :md="8">
          <div v-for="(item, index) in doc.cc" :key="'cc'+index" style="margin-bottom:20px">
            <ls-input-email
              :prop="'cc.'+index"
              v-model="doc.cc[index]"
              :contacts="contacts"
              required>
              <template slot="append">
                <el-button @click="removeArrayItem(doc.cc, index)"><i class="el-icon-delete"></i> {{ $t('ls.lsSignature.delete') }}</el-button>
              </template>
            </ls-input-email>
          </div>
        </el-col>
      </el-row>

      <el-button style="margin-top:10px" plain @click="addArrayItem(doc.cc, null)">
        <i class="el-icon-plus"></i> {{ $t('ls.lsSignature.addEmail') }}
      </el-button>
    </el-card>

    <el-card class="box-card" v-if="s.bcc" key="bcc-card">
      <div slot="header" class="clearfix">
        <span class="h3">{{ $t('ls.lsSignature.sendBlindCopy') }}</span>
      </div>

      <el-alert
        type="warning"
        :closable="false"
        show-icon>
        <div slot="title" style="word-break: break-word; text-align: justify;">
          {{ $t('ls.lsSignature.sendBlindCopyInfo') }}
        </div>
      </el-alert>

      <el-row>
        <el-col :md="8">
          <div v-for="(item, index) in doc.bcc" :key="'bcc'+index" style="margin-bottom:20px">
            <ls-input-email
              :contacts="contacts"
              :prop="'bcc.'+index"
              v-model="doc.bcc[index]"
              required>
              <template slot="append">
                <el-button @click="removeArrayItem(doc.bcc, index)"><i class="el-icon-delete"></i> {{ $t('ls.lsSignature.delete') }}</el-button>
              </template>
            </ls-input-email>
          </div>
        </el-col>
      </el-row>

      <el-button style="margin-top:10px" plain @click="addArrayItem(doc.bcc, null)">
        <i class="el-icon-plus"></i> {{ $t('ls.lsSignature.addEmail') }}
      </el-button>
    </el-card>

    <el-card class="box-card" v-if="s.cc" key="observer-card">
      <div slot="header" class="clearfix">
        <span class="h3">{{ $t('ls.lsSignature.observers') }}</span>
      </div>

      <el-alert
        type="warning"
        :closable="false"
        show-icon>
        <div slot="title" style="word-break: break-word; text-align: justify;">
          {{ $t('ls.lsSignature.observersInfo') }}
        </div>
      </el-alert>

      <el-row>
        <el-col :md="8">
          <div v-for="(item, index) in doc.observers" :key="'observers'+index" style="margin-bottom:20px">
            <ls-input-email
              :prop="'observers.'+index"
              v-model="doc.observers[index]"
              :contacts="contacts"
              required>
              <template slot="append">
                <el-button @click="removeArrayItem(doc.observers, index)"><i class="el-icon-delete"></i> {{ $t('ls.lsSignature.delete') }}</el-button>
              </template>
            </ls-input-email>
          </div>
        </el-col>
      </el-row>

      <el-button style="margin-top:10px" plain @click="addArrayItem(doc.observers, null)">
        <i class="el-icon-plus"></i> {{ $t('ls.lsSignature.addEmail') }}
      </el-button>
    </el-card>

    <template v-if="settingsVisible">
      <el-card class="box-card" v-if="s.message">
        <div slot="header" class="clearfix">
          <span class="h3">{{ $t('ls.lsSignature.messageForRecipients') }}</span>
        </div>

        <el-switch v-if="!bulk.enabled" v-model="doc.message_individual" :inactive-text="$t('ls.lsSignature.common')" :active-text="$t('ls.lsSignature.individual')"></el-switch>

        <template v-if="!doc.message_individual || bulk.enabled">
          <el-row>
            <el-col :md="12">
              <el-form-item :label="$t('ls.lsSignature.messageForAll')" optional>
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4}"
                  :placeholder="$t('ls.lsSignature.enterMessage')"
                  v-model="doc.message">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
        <template v-else>
          <el-row v-for="(subj, ix) in subjectsRemote" :key="'m'+ix">
            <el-col :md="12">
              <el-form-item :label="$t('ls.lsSignature.messageFor', { name: subj.signature.name })" optional>
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4}"
                  :placeholder="$t('ls.lsSignature.enterMessage')"
                  v-model="subj.signature.message">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </el-card>

      <el-card class="box-card" v-if="s.attachments">
        <div slot="header" class="clearfix">
          <span class="h3">{{ $t('ls.lsSignature.additionalEmailAttachments') }}</span>
        </div>

        <el-switch v-if="!bulk.enabled" v-model="doc.attachments_individual" :inactive-text="$t('ls.lsSignature.common')" :active-text="$t('ls.lsSignature.individual')"></el-switch>

        <template v-if="!doc.attachments_individual || bulk.enabled">
          <el-row>
            <el-col :md="12">
              <el-form-item :label="$t('ls.lsSignature.attachmentsForAll')" optional>
                <ls-upload
                  :pdf-convert="false"
                  v-model="doc.attachments">
                </ls-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
        <template v-else>
          <el-row v-for="(subj, ix) in subjectsRemote" :key="'a'+ix">
            <el-col :md="12">
              <el-form-item :label="$t('ls.lsSignature.attachmentsFor', { name: subj.signature.name })" optional>
                <ls-upload
                  v-model="subj.signature.attachments">
                </ls-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </el-card>
    </template>
  </div>

  <!-- BUTTONS -->
  <div class="footer-buttons">
    <template v-if="doc.active == 0">
      <el-button type="danger" @click="nextMerge" :disabled="!fileList.length || !uploadsFinished">{{ $t('ls.lsSignature.nextStep') }}</el-button>
    </template>
    <template v-if="doc.active == 1">
      <el-button @click="prevSubjects" v-if="s.fileUpload">{{ $t('ls.lsSignature.previousStep') }}</el-button>
      <el-button type="danger" :disabled="!doc.file" @click="nextSubjects"><i v-if="!doc.file" class="el-icon-loading"></i> {{ $t('ls.lsSignature.nextStep') }}</el-button>
    </template>
    <template v-if="doc.active == 2">
      <el-button @click="prev">{{ $t('ls.lsSignature.previousStep') }}</el-button>
      <el-button type="danger" @click="nextPlacing">{{ $t('ls.lsSignature.nextStep') }}</el-button>
    </template>
    <template v-if="doc.active == 3">
      <el-button :disabled="submitLoading" @click="prev">{{ $t('ls.lsSignature.previousStep') }}</el-button>
      <el-button :disabled="submitLoading" type="danger" @click="submitFn()">{{ $t('ls.lsSignature.sendForSignature') }}</el-button>
    </template>
  </div>

</el-form>
</template>

<script>
import SignComposer from './signComposer.vue';
import axiosRetry from 'axios-retry';
import ContactCard from '@/components/Contact/ContactCard.vue';

export default {
  components: {
    SignComposer,
    ContactCard
  },
  props: {
    //logged user as predefined signer
    user: {
      type: Object,
      required: true
    },
    //assign to contract
    contract: {
      type: Number,
      default: null
    },
    //assign to branch
    branch: {
      type: Number,
      default: null
    },
    //ls contacts enabled?
    contacts: {
      type: Boolean,
      default: false
    },
    //bankID enabled?
    bankid: {
      type: Boolean,
      default: false
    },
    //old 'contacts' enabled? (used as ENUM storage)
    suggestions: {
      type: Boolean,
      default: false
    },
    //injected 'contacts' enabled? (used from external source)
    injected: {
      type: Array,
      default: () => []
    },
    //preset tags
    tags: {
      type: Array,
      default: () => []
    },

    //enable bulk signature
    bulkEnabled: {
      type: Boolean,
      default: false
    },

    //files preload (external)
    files: {
      type: Array,
      default: () => []
    },
    //prefill subjects (external)
    subjects: {
      type: Array,
      default: () => []
    },

    //webhook for change propagation (external) - deprecated (realman only)
    webhook: {
      type: String,
      default: null
    },
    //tunnel submit request to different URL (external)
    tunnel: {
      type: Object,
      default: null
    },
    //settings object to fine tune behavior (external)
    settings: {
      type: Object,
      default: null
    }
  },
  provide() {
    return {
      user: this.user,
    }
  },
  data() {
    return {
      //empty filelist
      fileList: [],
      //empty submit object
      doc: {
        active: 0,
        title: "",
        contract_id: this.contract,
        branch_id: this.branch,
        file: null,
        message_individual: false,
        message: "",
        attachments_individual: false,
        attachments: [],
        coordinates: {},
        expiration: 14,
        resend: 7,
        notifications: true,
        order: false,
        language: "cz",
        pass: null,
        tags: this.tags.map(x => { return {value:x}}),
        cc: [],
        bcc: [],
        observers: [],
        subjects: [],
        webhook: this.webhook,
      },
      //empty submit object for bulk signature
      bulk: {
        enabled: false,
        active: 0,
        zip: false,
        zipDirectory: '',
        zipList: [],
        data: [],
        myData: [],
        currentPage: 0,
        maxPage: 20
      },
      //default settings
      s: {
        fileUpload: true,
        fileLock: true,
        cc: true,
        bcc: true,
        message: true,
        attachments: true,
      },

      //helper data
      csvLoading: false,
      submitLoading: false,
      subjChangeKey: 0,
    }
  },

  created: async function() {
    this.preloadFiles();

    //add user as subject
    this.doc.subjects.push(this.getEmptySubject({
      subject: "me",
      type: "local",
      name: this.user.name,
      sign: this.user.signOutput,
      email: this.user.email,
      phone: this.user.phone,
      code: this.genRandCode(),
    }));
    this.doc.cc.push(this.user.email);
    this.doc.observers.push(this.user.email);
    if(this.subjects) {
      this.subjects.forEach(x => {
        this.doc.subjects.push(this.getEmptySubject({
          name: x.name,
          email: x.email,
          phone: x.phone
        }));
      });
    }

    //override default settings of form
    if(this.settings)
      this.s = Object.assign(this.s, this.settings);

    if(this.$i18n.locale != 'cz') {
      this.doc.language = this.$i18n.locale;
    }

  },

  mounted() {
    axiosRetry(this.$api, { retries: 3 });

    //change default values for settings (cc, bcc, expiration and resend notification time)
    if(this.$context?.signature?.expiration)
      this.doc.expiration = this.$context?.signature.expiration;
    if(this.$context?.signature?.resend)
      this.doc.resend = this.$context?.signature.resend;
    if(this.$context?.signature?.cc)
      this.doc.cc = [...(this.$context?.signature.cc || []), ...this.doc.cc];
    if(this.$context?.signature?.bcc)
      this.doc.bcc = [...(this.$context?.signature.bcc || []), ...this.doc.bcc];

    //hide file upload step (external solutions)
    if(this.s.fileUpload === false)
      this.nextMerge();
  },

  computed: {
    /**
     * for external templates, access external API
     */
    apiEndpoint() {
      var url = "/proculus-api";
      if(this.$context.access == "external")
        url = "/external-api/" + this.$context.client.slug;

      return this.hostname + url;
    },

    /**
     * headers for el-upload with external token support
     */
    uploadHeaders() {
      return this.tunnel?.headers || this.$api.defaults.headers.common;
    },

    /**
     * for bulk signature
     */
    subjectsVariable() {
      return this.doc.subjects.filter(x => x.signature.subject == 'variable');
    },
    subjectsRemote() {
      return this.doc.subjects.filter(x => this.isRemote(x));
    },
    settingsVisible() {
      return this.subjectsRemote.length;
    },
    uploadsFinished() {
      return this.fileList.filter(x => x && x.status != "success").length == 0;
    },
    bankIds() {
      return this.doc.subjects
          .filter(subject => subject.signature.type === 'remote' && subject.signature.bankid)
          .map(subject => subject.signature.i);
    }
  },
  methods: {

    /**
     * pre-inject files from prop if provided
     */
    preloadFiles() {
      this.files?.forEach(x => {
        let name = x.name;
        let mime = x.data.split(';')[0].split(':')[1];
        let base64 = x.data.split(',')[1];        

        let index = this.fileList.push({
          name: name.includes('.') ? name.split('.').slice(0, -1).join('.') : name,
          response: {
            file: null
          },
          size: null,
          status: "loading",
          titleEditing: false,
        });

        if(mime == 'application/pdf') {
          this.setPreloadedFile(index-1, base64);
        }
        else {
          this.$api.post(this.apiEndpoint+'/podpisy/upload', { base64, name, mime }).then(x => {
            this.setPreloadedFile(index-1, x.data.file);
          });
        }
      })
    },

    setPreloadedFile(index, base64) {
      this.fileList[index].response.file = base64;
      this.fileList[index].status = "success";
      this.fileList[index].size = Math.round(base64.length*3/4);
      this.fileList = this.fileMap(this.fileList);
    },

    clearPhone(subj) {
      if(!subj.signature.phoneEnabled)
        subj.signature.phone = "";
    },

    isRemote(x) {
      return x.signature && (x.signature.type == 'remote' || x.signature.type == 'remote-review');
    },

    genRandCode() {
      var minm = 10000;
      var maxm = 99999;
      return (Math.floor(Math.random() * (maxm - minm + 1)) + minm).toString();
    },

    getEmptySubject(obj = null) {
      const s = this.$globalHelpers.getEmptyContact();
      s.signature = {
        subject: "else",
        type: "remote",

        name: "",
        email: "",
        phone: "",
        code: this.genRandCode(),
        reviewEmail: false,
        phoneEnabled: true,
        message: "",
        attachments: [],
        sign: null,
        verif: false,
        kyc: false,
        kycsettings: {
          require_additional_document: false,
          micropayment: {
            enabled: false,
            cu: null,
            vs: null,
            comment: null
          }
        },
        ...obj
      };

      return s;
    },

    subjectChanged(subject) {
      if(subject.signature.subject == "me") {
        subject.signature.name = this.user.name;
        subject.signature.email = this.user.email;
        subject.signature.phone = this.user.phone;
        subject.signature.type = "local";
      }
      else {
        subject.signature.name = null;
        subject.signature.email = null;
        subject.signature.phone = null;
        subject.signature.sign = null;
        subject.signature.type = "remote";
      }

      this.localFirst();
    },

    handleEdit(index) {
      this.fileList[index].titleEditing = true;
    },

    handleDelete(index) {
      this.fileList.splice(index, 1);
    },

    handleSuccessZip(response, file, fileList) {
      if(!file ||
        file.status != "success" ||
        !response ||
        !response.name ||
        response.mime != "application/pdf"
      ) {
        console.log(file);
        console.log(response);
        this.$message.error(this.$t('ls.lsSignature.error.error')+this.$t('ls.lsSignature.error.fileUploadError')+this.$t('ls.lsSignature.error.reported'));
        this.$sentry.captureException(response);
        return;
      }
      this.bulk.zipDirectory = response.directory;
      this.bulk.zipList = response.fileList;
      this.fileList.push(file);
    },

    handleHttp(options) {
      var bodyFormData = new FormData();
      bodyFormData.append('file', options.file);

      return this.$api({
        method: 'post',
        url: options.action,
        data: bodyFormData,
        headers: {
          ...options.headers,
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress: options.onProgress
      }).then(x=>x.data).catch(error => {
        if(error?.response?.status === 415) {
          throw error;
        } else {
          console.log(error);
          this.$message.error(this.$t('ls.lsSignature.error.error')+this.$t('ls.lsSignature.error.fileUploadError')+this.$t('ls.lsSignature.error.reported'));
          this.$sentry.captureException(error);
        }
      });
    },

    beforeUpload(file) {
      const maxSize = 6; //MB

      var size = this.fileList.reduce((x, y) => x + y.size, file.size);

      const isSmall = (size / 1024 / 1024) < maxSize;

      if(!isSmall)
        this.$message.error(this.$t('ls.lsSignature.error.fileSizeExceeded'));

      return isSmall;
    },

    handleSuccess(response, file, fileList) {
      if(file?.status != "success" ||
        file.response?.mime != "application/pdf" ||
        !file.response.name
      ) {
        return;
      }

      this.fileList = this.fileMap(fileList);
    },

    handleError(err, file, fileList) {
      console.log(err);
      console.log(file);
      console.log(fileList);
      if(err.status == 415 || err.response?.status == 415) {
        this.$message.warning(this.$t('ls.lsSignature.error.error')+this.$t('ls.lsSignature.error.fileNotSupported', { file: file.name }));
      }
      else {
        this.$message.error(this.$t('ls.lsSignature.error.error')+this.$t('ls.lsSignature.error.fileUploadError')+this.$t('ls.lsSignature.error.reported'));
        this.$sentry.captureException(err);
      }

      this.fileList = fileList;
    },

    handleProgress(event, file, fileList) {
      this.fileList = this.fileMap(fileList);
    },

    fileMap(fileList) {
      return fileList.map(file => {
        //for new entries
        if(file.titleEditing === undefined) {
          this.$set(file, 'titleEditing', false);
          file.name = file.name.replace(/\.[^/.]+$/, "");
        }
        return file;
      });
    },

    signatureTypeChange(subject) {
      subject.signature.sign = null;
      subject.signature.verif = subject.signature.kyc = false;

      this.localFirst();
    },


    /**
     * ensure, that locally signing subjects are in front of remote
     */
    localFirst() {
      if(this.doc.order) {
        var before = JSON.stringify(this.doc.subjects);
        this.doc.subjects.sort((a, b) => {
          var aR = this.isRemote(a);
          var bR = this.isRemote(b);

          if(aR && !bR)
            return 1;
          if(!aR && bR)
            return -1;

          return 0;
        });
        var after = JSON.stringify(this.doc.subjects);

        if(before != after)
          this.$message.warning(this.$t('ls.lsSignature.signatureOrderChanged'));

        this.subjChangeKey++;
      }
    },

    nextSubjects() {
      if(this.bulk.enabled) {
        if(this.bulk.active == 0) {
          this.bulk.active++;
          this.bulk.myData = this.bulk.data = [];
        }
        else {
          this.$refs['form2'].validate((valid) => {
            if (!valid) {
              this.$message({
                showClose: true,
                message: this.$t('ls.lsSignature.error.formInvalid'),
                type: 'error'
              });
              return false;
            }
            this.doc.active++;
            this.$nextTick(() => { window.scrollTo({ top: "50px", behavior: 'smooth' }) });
          });
        }
      }
      else
        this.next();
    },

    prevSubjects() {
      this.doc.file = null;

      if(this.bulk.enabled && this.bulk.active)
        this.bulk.active--;
      else
        this.prev();
    },

    /**
     * merge all uploaded files
     * + next step
     */
    async nextMerge() {
      this.next();

      if(this.fileList.length > 1) {
        try {
          this.doc.file = await this.$api.post(this.apiEndpoint+'/podpisy/merge', {
            pdfs: this.fileList.map(x=>x.response.file),
          }).then(res => res.data);
        }
        catch(error) {
          console.log(this.fileList);
          this.$message.error(this.$t('ls.lsSignature.error.error')+this.$t('ls.lsSignature.error.fileMergeFailed')+this.$t('ls.lsSignature.error.reported'));
          this.$sentry.captureException(error);
        }
      }
      else {
        this.doc.file = this.fileList[0]?.response?.file;
      }
      this.doc.title = this.fileList[0].name;
    },

    /**
     * check if all signing subjects were placed on canvas
     * check if Bank iD signing subjects have maximum of 1 signature
     * + next step
     */
    nextPlacing() {
      //get ids of placed subjects
      let ids = [...new Set(Object.values(this.doc.coordinates).map(x => x.sign))];

      //get ids of not placed subjects
      let loners = this.doc.subjects
        .filter((_, i) => !ids.includes(i))
        .filter(x => x.signature && x.signature.type == 'remote');

      //check if all subjects were placed at least once
      if(loners.length) {
        this.$message.error(this.$t('ls.lsSignature.error.warning')+this.$t('ls.lsSignature.error.allSignaturesNotPlaced', { numbers: loners.map((_, i) => i+1).join(', ')}));
        return;
      }

      // check that Bank iD contains maximum of 1 position per subject
      let allSigns = Object.values(this.doc.coordinates).map(x => x.sign);
      for (let i = 0; i < this.bankIds.length; i++) {
          if (allSigns.filter(sign => sign === this.bankIds[i]).length > 1) {
            this.$message.error(this.$t('ls.lsSignature.error.warning')+this.$t('ls.lsSignature.error.bankIdMultipleSignatures', { number: this.bankIds[i] + 1 }));
            return;
          }
      }

      this.next();
    },

    /**
     * map subjects before submit
     * assign ip address and date to local subjects
     */
    subjectsMapping() {
      return this.doc.subjects.map(subject => {
        return {
          ...subject.signature,
          contact_id: subject.contact_id ? subject.contact_id : undefined
        }
      });
    },

    contactToSignatoryMapper(signatory, contact) {
      signatory.contact_id = contact?.id;
      signatory.type = contact?.type;

      signatory.signature.name = contact?.full_name;
      signatory.signature.phone = contact?.phone;
      signatory.signature.email = contact?.email;
    },

    signatoryToContactMapper(signatory, contact) {
      contact.id = signatory.contact_id;
      contact.type = signatory.type;

      contact.full_name = signatory.signature.name;

      let name = this.$stringHelpers.splitName(signatory.signature.name);
      contact.name = name.name;
      contact.surname = name.surname;
      contact.middlename = name.middlename;
      contact.prefix = name.prefix;
      contact.sufix = name.sufix;

      contact.phone = signatory.signature.phone;
      contact.email = signatory.signature.email;
    },

    /**
     * final submit button pressed
     */
    async submitFn() {
      try {
        this.submitLoading = true;
        await this.$nextTick();

        let backend_url = this.apiEndpoint + '/podpisy/vytvorit';

        //parameters for API call
        const url = this.tunnel?.route || backend_url;
        const data = {
          pass_url: backend_url,
          data: {
            ...this.doc,
            subjects: this.subjectsMapping(),
            coordinates: Object.values(this.doc.coordinates),
            tags: this.doc.tags.map(x => x.value),
            cc: this.doc.cc.filter(x => x), //filter non-null values
            bcc: this.doc.bcc.filter(x => x),
            observers: this.doc.observers.filter(x => x),
          },
          bulk: this.bulk.enabled ? this.bulk : undefined
        };

        var res = await this.$http.post(url, data, { headers: this.uploadHeaders }).then(x => x.data);
        this.$emit('finish', res);
      }
      catch(error) {
        this.submitLoading = false;
        console.log(error);
        this.$message.error(this.$t('ls.lsSignature.error.error')+this.$t('ls.lsSignature.error.submitFailed')+this.$t('ls.lsSignature.error.reported'));
        this.$sentry.captureException(error);
      }
    },

    /**
     * reorder subjects
     */
    moveItem(arr, from, to) {
      if(arr.length <= to || to < 0)
        return;

      //moving up only within remotes
      if(from > to && !this.isRemote(arr[to])) {
        this.$message.warning(this.$t('ls.lsSignature.error.warning')+this.$t('ls.lsSignature.error.signatureOrder'));
        return;
      }

      // remove `from` item and store it
      var f = arr.splice(from, 1)[0];
      // insert stored item into position `to`
      arr.splice(to, 0, f);

      this.subjChangeKey++;
    },

    /**
     * BULK SIGNATURE
     * load subjects from CSV
     */
    async loadCSV(file) {
      this.csvLoading = true;
      await this.$nextTick();

      var reader = new FileReader();
      reader.onload = async () => {
        var data = this.$globalHelpers.csvToJSON(reader.result);
        var sCount = this.subjectsVariable.length;
        data.map(x => {
          x["subjects"] = [];
          for(let i=1; i<=sCount ;i++) {
            x["subjects"].push(this.getEmptySubject({
              name: x["subject_"+i+"_name"],
              email: x["subject_"+i+"_email"],
              verif: x["subject_"+i+"_verif"] ? true : false,
              phone: x["subject_"+i+"_phone"],
              code: x["subject_"+i+"_code"] ? x["subject_"+i+"_code"] : this.genRandCode(),
            }));

            delete x["subject_"+i+"_name"];
            delete x["subject_"+i+"_email"];
            delete x["subject_"+i+"_verif"];
            delete x["subject_"+i+"_phone"];
            delete x["subject_"+i+"_code"];
          };
          return x;
        });
        this.bulk.data = data;
        this.bulk.myData = this.bulk.data.slice(0, this.bulk.maxPage);

        await this.$nextTick();
        this.csvLoading = false;
      };

      reader.readAsText(file.file);
    },

    /**
     * BULK SIGNATURE
     */
    getIndex(index) {
      return (this.bulk.currentPage-1)*this.bulk.maxPage+index;
    },

    /**
     * BULK SIGNATURE
     */
    handleCurrentChange(ix, data) {
      var x = ix-1;
      this.bulk.myData = data.slice(x*this.bulk.maxPage, x*this.bulk.maxPage + this.bulk.maxPage);
    },

    /**
     * BULK SIGNATURE
     */
    removeItem(data, index) {
      data.splice(this.getIndex(index), 1);
      this.handleCurrentChange(this.bulk.currentPage, data);
    },

    /**
     * BULK SIGNATURE
     */
    addItem() {
      var empty = {
        subjects: [],
        file: null,
        pass: null,
      };

      for(let subj in this.subjectsVariable) {
        empty.subjects.push(this.getEmptySubject());
      }

      this.addArrayItem(this.bulk.data, empty);
      this.bulk.currentPage = Math.ceil(this.bulk.data.length/this.bulk.maxPage);

      this.$nextTick(() => {
        let el = document.querySelector(".el-table__body-wrapper .el-table__body tbody .el-table__row:last-child");
        el?.scrollIntoView({behavior: 'smooth', block: 'end'});
      });

      this.handleCurrentChange(this.bulk.currentPage, this.bulk.data);
    },

    /**
     * BULK SIGNATURE
     * download CSV template
     */
    downloadTemplate() {
      var header = [];
      var sample1 = [];
      var sample2 = [];

      for(let [ix, subj] of this.subjectsVariable.entries()) {
        header.push("subject_"+(ix+1)+"_name");
        sample1.push("Jmeno a Prijmeni");
        sample2.push("Dalsi osoba");

        header.push("subject_"+(ix+1)+"_email");
        sample1.push("email@email.cz");
        sample2.push("email2@email.cz");

        header.push("subject_"+(ix+1)+"_verif");
        sample1.push("ANO");
        sample2.push("");

        header.push("subject_"+(ix+1)+"_phone");
        sample1.push("777 777 777");
        sample2.push("");

        header.push("subject_"+(ix+1)+"_code");
        sample1.push("12345");
        sample2.push("");
      }

      if(this.bulk.zip) {
        header.push("file");
        sample1.push("soubor.pdf");
        sample2.push("soubor2.pdf");
      }

      header.push("pass");
      sample1.push("heslo-na-pdf-123");
      sample2.push("");

      var data = [header, sample1, sample2];

      const csvContent = `data:text/csv;charset=utf-8,${data.map((row, index) => row.join(';')).join(`\n`)}`;

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "import.csv");
      document.body.appendChild(link);

      link.click();
    }
  }
}
</script>

<style lang="scss" scoped>
  .footer-buttons {
    display: flex;
    justify-content: space-between;
  }

  .footer-buttons > *:only-child {
    margin-left: auto;
  }

  .fileList {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 8px;
    color: #606266;
    border: 1px solid #e6e6e6;
    box-shadow: 2px 2px 2px 0px #EEE;
    margin-bottom: 20px;
  }

  .fileList .icon {
    font-size: 40px;
    margin-right: 10px;
  }

  .fileList .el-dropdown-link {
    font-size: 20px;
  }

  .fileButtons {
    margin-left: auto;
    padding-right: 5px;
  }

  .fileButtons .el-button--text {
    color: #606266;
  }

  .person-card {
    margin-bottom: 20px;
  }

  .reorder {
    margin: 0 !important;
    padding: 0;
    color: inherit;
  }
  .strana-header {
    display: flex;
    align-items: center;
    gap: 1em;
  }
  .strana-header .h4 {
    margin: 0;
  }
  .remove-person-button {
    color: inherit;
      float: initial;
      margin-left: auto;
  }
  .radio-card {
    margin-bottom: 0;
  }

  .radio-card .el-radio-button {
    margin-bottom: 0;
  }

  .el-icon-question {
    color: #999;
  }

  .el-input .el-checkbox {
    margin-bottom: 0;
  }

  .el-table .el-form-item {
    margin-bottom: 0;
  }
  .el-table .el-checkbox {
    margin-bottom: 0;
  }

</style>

<i18n>
{
  "cz": {
    "ls": {
      "lsSignature": {
        "sending": "Dokument se posílá",
        "bulkSignature": "Hromadný",
        "forBulk": "hromadnýmu",
        "electronicSignature": "Elektronický podpis",
        "uploadDocuments": "Nahrání dokumentů",
        "signingParties": "Podepisující strany",
        "signaturePlacement": "Umístění podpisu",
        "submission": "Odeslání",
        "uploadDocumentsFor": "Nahrání dokumentů k",
        "signature": "podpisu",
        "bulkSignatureType": "Typ hromadného podpisu",
        "singleFile": "jeden soubor",
        "multipleFiles": "vícero souborů",
        "uploadInstructions": "Zde vložte dokument, který chcete podepsat. Pokud vložíte více dokumentů, sloučí se do jednoho PDF.",
        "fileName": "Název",
        "dragFileHere": "Přeneste dokument zde, anebo",
        "clickToUpload": "klikněte pro nahrání",
        "supportedFiles": "Podporované soubory jsou PDF, Microsoft Word a Excel",
        "fileSizeLimit": "Velikost souborů nemůže překročit 6 MB",
        "fileCompressionAdvice": "Pro zmenšení velikosti doporučujeme soubor zkomprimovat například",
        "here": "zde",
        "bulkDispatch": "Hromadná rozesílka",
        "bulkUploadInstructions": "Pokud chcete podepsat dávku souborů se stejným umístěním podpisových polí, nahrajte je jako ZIP archiv.",
        "uploadZipArchive": "Nahrát ZIP archiv",
        "signingPartiesInfo": "Zadejte údaje podepisujících stran, jimž bude dokument zaslán k podpisu a jimž bude následně odeslána kopie podepsaného dokumentu.",
        "variableSignatorySetting": "Pro nastavení subjektů skrz hromadné rozesílání zvolte variabilního podepisujícího.",
        "signingOrder": "Záleží na pořadí podepisujících stran",
        "signingOrderInfo": "Pokud potřebujete, aby se dokument dostal ostatním k podpisu v stanoveném pořadí.",
        "signatory": "Podepisující",
        "numberedSignatory": "č. {number}",
        "me": "jsem já",
        "someoneElse": "je někdo další",
        "variable": "je variabilní",
        "deleteParty": "Smazat stranu",
        "fullName": "Jméno a příjmení",
        "choose": "Zvolte",
        "local": "Nyní a na tomto zařízení",
        "remote": "Na dálku",
        "remoteApprovalOnly": "Pouze schválení na dálku",
        "sendSignedOriginal": "Odeslat podepsaný originál",
        "twoFactorAuth": "Dvoufaktorové ověření",
        "twoFactorAuthInfo": "Pokud si chcete ověřit, že dokument podepisuje správná osoba, můžete si jej zabezpečit automaticky vygenerovaným heslem. Bez tohoto hesla nelze dokument zobrazit ani podepsat.",
        "kycVerification": "KYC ověření",
        "kycVerificationInfo": "Ověření strany pomocí dokladu totožnosti a selfie.",
        "bankIdSign": "Bank iD SIGN",
        "bankIdSignInfo": "Podpis pomocí služby Bank iD SIGN je dodatečně zpoplatněn částkou 5,49 Kč za podepisující stranu.",
        "password": "Heslo",
        "passwordLength": "Heslo musí mít 4-8 znaků",
        "phoneForPassword": "Telefonní číslo pro zaslání hesla",
        "micropayment": "Mikroplatba",
        "requireAdditionalDocument": "Vyžadovat dva doklady totožnosti",
        "requireAdditionalDocumentInfo": "U druhého dokladu neprobíhá automatické vytěžování údajů",
        "accountNumber": "Číslo účtu",
        "variableSymbol": "Variabilní symbol",
        "paymentNote": "Poznámka/zpráva platby",
        "variableSubject": "Variabilní subjekt - zadáno v dalším kroku.",
        "addSignatory": "Přidat podepisujícího",
        "bulkData": "Hromadná data",
        "bulkDataInstructions": "V tomto kroku se nastavuje dávkové rozesílání. Dávku lze nastavit ručně přidáním řádků do tabulky níže, nebo nahráním šablony v CSV (UTF8). Šablona kopíruje nastavení tabulky a je závislá na počtu variabilních subjektů.",
        "downloadCsvTemplate": "Stáhnout šablonu CSV",
        "uploadCsvFile": "Nahrát CSV (UTF8) soubor",
        "variableParty": "Variabilní strana {number}",
        "name": "Jméno",
        "email": "Email",
        "2fa": "2FA",
        "phone": "Telefon",
        "phoneFor2fa": "Telefon pro zaslání SMS s heslem pro dvoufaktorové ověření. Pokud telefon nevyplníte, heslo se nebude odesílat.",
        "file": "Soubor",
        "zipFileInfo": "Soubor ze ZIP archivu",
        "pdfLock": "Zámek PDF",
        "pdfLockInfo": "Podepsaný PDF originál lze opatřit heslem.",
        "pdfLockNotAvailable": "Heslo nelze nastavit při použití Bank iD.",
        "addItem": "Přidat položku",
        "dragSignatureField": "Podpisová pole přetáhněte do dokumentu tam, kde se mají zobrazit podpisy.",
        "settings": "Nastavení",
        "reminderEveryXDays": "Upozornění zaslat každých x dní",
        "days": "dni",
        "linkExpirationDays": "Počet dní platnosti odkazu",
        "signingNotification": "Notifikace o podepsání",
        "linkLanguage": "Jazyk odkazu",
        "select": "Vyberte",
        "pdfPassword": "Heslo PDF",
        "tags": "Značky",
        "tagsInfo": "Značky lze použít pro lepší přehled a filtraci nahraných dokumentů. Pokud chcete značku uložit pro budoucí použití, vytvořte ji v nabídce tlačítkem vpravo. Pokud chcete použít značku bez uložení, stačí ji přímo napsat a ve výsuvné nabídce na ni kliknout.",
        "addTags": "Přidejte značky",
        "sendCopy": "Zaslat podepsaný dokument v kopii (CC)",
        "sendCopyInfo": "Podepsaný originál se automaticky pošle všem podepisujícím a schvalujícím stranám. Pokud ho chete poslat dodatečným stranám, můžete je zde přidat.",
        "sendBlindCopy": "Zaslat podepsaný dokument ve skryté kopii (BCC)",
        "sendBlindCopyInfo": "Podepsaný originál se automaticky pošle všem podepisujícím a schvalujícím stranám. Pokud ho chete poslat dodatečným stranám, můžete je zde přidat.",
        "observers": "Sledovatelé podpisu",
        "observersInfo": "Sledovatelé podpisu budou informováni e-mailem o stavu podpisového procesu. Nemají možnost podpisovat dokument, či vidět jeho obsah.",
        "delete": "Smazat",
        "addEmail": "Přidat E-mail",
        "messageForRecipients": "Zpráva pro příjemce",
        "common": "společná",
        "individual": "individuální",
        "messageForAll": "Zpráva pro všechny příjemce (nepovinný)",
        "enterMessage": "Zadejte zprávu...",
        "messageFor": "Zpráva pro {name} (nepovinný)",
        "additionalEmailAttachments": "Dodatečné přílohy e-mailu",
        "attachmentsForAll": "Příloha pro všechny příjemce (nepovinný)",
        "attachmentsFor": "Příloha pro {name} (nepovinný)",
        "nextStep": "Další krok",
        "previousStep": "Krok zpět",
        "sendForSignature": "Odeslat k podpisu",
        "signatureMethod": "Způsob podpisu",
        "language": {
          "cz": "Čeština",
          "en": "Angličtina"
        },
        "error": {
          "warning": "Pozor! ",
          "formInvalid": "Zkontrolujte zadané údaje.",
          "error": "Nastala chyba! ",
          "reported": "Náš tým byl informován.",
          "fileNotSupported": "Soubor {name} není podporován.",
          "fileUploadFailed": "Nahrávání souboru {name} selhalo.",
          "fileMergeFailed": "Spojování souborů selhalo.",
          "fileUploadError": "Nahrávání souboru selhalo.",
          "fileSizeExceeded": "Velikost souborů nesmí překročit 6 MB.",
          "allSignaturesNotPlaced": "Nebyly umístněny všechny podpisy. Doplňte prosím podpisy č. {numbers}.",
          "bankIdMaxSignatures": "Bank iD umožňuje pouze 1 pozici pro každou podpisovou stranu. Opravte prosím podpis č. {number}.",
          "submitFailed": "Odeslání k podpisu selhalo.",
          "signatureOrder": "Nelze přemístnít před strany podepisující 'nyní a na tomto zařízení'.",
          "signatureOrderChanged": "Došlo k automatické změne pořadí. Strany podepisující 'nyní a na tomto zařízení' musí být první."
        }
      }
    }
  },
  "en": {
    "ls": {
      "lsSignature": {
        "sending": "Document is being sent",
        "bulkSignature": "Bulk",
        "forBulk": "bulk",
        "electronicSignature": "Electronic Signature",
        "uploadDocuments": "Upload Documents",
        "signingParties": "Signing Parties",
        "signaturePlacement": "Signature Placement",
        "submission": "Submission",
        "uploadDocumentsFor": "Upload Documents for",
        "signature": "Signature",
        "bulkSignatureType": "Bulk Signature Type",
        "singleFile": "Single File",
        "multipleFiles": "Multiple Files",
        "uploadInstructions": "Upload the document you want to sign. If you upload multiple documents, they will be merged into one PDF.",
        "fileName": "File Name",
        "dragFileHere": "Drag file here, or",
        "clickToUpload": "click to upload",
        "supportedFiles": "Supported files are PDF, Microsoft Word, and Excel",
        "fileSizeLimit": "File size cannot exceed 6 MB",
        "fileCompressionAdvice": "To reduce file size, we recommend compressing the file, for example",
        "here": "here",
        "bulkDispatch": "Bulk Dispatch",
        "bulkUploadInstructions": "If you want to sign a batch of files with the same signature field placement, upload them as a ZIP archive.",
        "uploadZipArchive": "Upload ZIP Archive",
        "signingPartiesInfo": "Enter the details of the signing parties to whom the document will be sent for signing and who will subsequently receive a copy of the signed document.",
        "variableSignatorySetting": "To set subjects for bulk dispatch, choose a variable signatory.",
        "signingOrder": "Signing order matters",
        "signingOrderInfo": "If you need the document to reach others for signing in the specified order.",
        "signatory": "Signatory",
        "numberedSignatory": "Signatory No. {number}",
        "me": "It's me",
        "someoneElse": "It's someone else",
        "variable": "It's variable",
        "deleteParty": "Delete Party",
        "fullName": "Full Name",
        "choose": "Choose",
        "local": "Now and on this device",
        "remote": "Remotely",
        "remoteApprovalOnly": "Approval only remotely",
        "sendSignedOriginal": "Send signed original",
        "twoFactorAuth": "Two-Factor Authentication",
        "twoFactorAuthInfo": "If you want to verify that the correct person is signing the document, you can secure it with an automatically generated password. The document cannot be viewed or signed without this password.",
        "kycVerification": "KYC Verification",
        "kycVerificationInfo": "Verification of the party using an ID card and a selfie.",
        "bankIdSign": "Bank iD SIGN",
        "bankIdSignInfo": "Signing with the Bank iD SIGN service is additionally charged at 5.49 CZK per signing party.",
        "password": "Password",
        "passwordLength": "Password must be 4-8 characters long",
        "phoneForPassword": "Phone number for sending password",
        "micropayment": "Micropayment",
        "requireAdditionalDocument": "Require two identification documents",
        "requireAdditionalDocumentInfo": "Second document will not be automatically processed",
        "accountNumber": "Account Number",
        "variableSymbol": "Variable Symbol",
        "paymentNote": "Payment Note/Message",
        "variableSubject": "Variable subject - entered in the next step.",
        "addSignatory": "Add Signatory",
        "bulkData": "Bulk Data",
        "bulkDataInstructions": "In this step, batch dispatch is set. The batch can be set manually by adding rows to the table below, or by uploading a template in CSV (UTF8). The template mirrors the table settings and depends on the number of variable subjects.",
        "downloadCsvTemplate": "Download CSV Template",
        "uploadCsvFile": "Upload CSV (UTF8) File",
        "variableParty": "Variable Party {number}",
        "name": "Name",
        "email": "Email",
        "2fa": "2FA",
        "phone": "Phone",
        "phoneFor2fa": "Phone for sending SMS with password for two-factor authentication. If you do not fill in the phone, the password will not be sent.",
        "file": "File",
        "zipFileInfo": "File from ZIP archive",
        "pdfLock": "PDF Lock",
        "pdfLockInfo": "The signed PDF original can be password protected.",
        "pdfLockNotAvailable": "Password cannot be set when using Bank iD.",
        "addItem": "Add Item",
        "dragSignatureField": "Drag the signature fields to the document where the signatures should appear.",
        "settings": "Settings",
        "reminderEveryXDays": "Send reminder every x days",
        "days": "days",
        "linkExpirationDays": "Link expiration days",
        "signingNotification": "Signing Notification",
        "linkLanguage": "Link Language",
        "select": "Select",
        "pdfPassword": "PDF Password",
        "tags": "Tags",
        "tagsInfo": "Tags can be used for better overview and filtering of uploaded documents. If you want to save a tag for future use, create it in the offer with the button on the right. If you want to use a tag without saving, just write it and click on it in the drop-down menu.",
        "addTags": "Add Tags",
        "sendCopy": "Send signed document in copy (CC)",
        "sendCopyInfo": "The signed original is automatically sent to all signing and approving parties. If you want to send it to additional parties, you can add them here.",
        "sendBlindCopy": "Send signed document in blind copy (BCC)",
        "sendBlindCopyInfo": "The signed original is automatically sent to all signing and approving parties. If you want to send it to additional parties, you can add them here.",
        "observers": "Signature Observers",
        "observersInfo": "Signature Observers will be informed by email about the status of the signing process. They do not have the ability to sign the document or see its content.",
        "delete": "Delete",
        "addEmail": "Add Email",
        "messageForRecipients": "Message for Recipients",
        "common": "common",
        "individual": "individual",
        "messageForAll": "Message for all recipients (optional)",
        "enterMessage": "Enter message...",
        "messageFor": "Message for {name} (optional)",
        "additionalEmailAttachments": "Additional Email Attachments",
        "attachmentsForAll": "Attachment for all recipients (optional)",
        "attachmentsFor": "Attachment for {name} (optional)",
        "nextStep": "Next Step",
        "previousStep": "Previous Step",
        "sendForSignature": "Send for Signature",
        "signatureMethod": "Signature Method",
        "language": {
          "cz": "Czech",
          "en": "English"
        },
        "error": {
          "warning": "Warning! ",
          "formInvalid": "Check the entered data.",
          "error": "An error occurred! ",
          "reported": "Our team has been informed.",
          "fileNotSupported": "File {name} is not supported.",
          "fileUploadFailed": "File upload {name} failed.",
          "fileMergeFailed": "Merging files failed.",
          "fileUploadError": "File upload failed.",
          "fileSizeExceeded": "File size cannot exceed 6 MB.",
          "allSignaturesNotPlaced": "Not all signatures have been placed. Please complete signatures no. {numbers}.",
          "bankIdMaxSignatures": "Bank iD allows only 1 position for each signing party. Please correct signature no. {number}.",
          "submitFailed": "Submission for signature failed.",
          "signatureOrder": "Cannot move before the parties signing 'now and on this device'.",
          "signatureOrderChanged": "The order has been automatically changed. The parties signing 'now and on this device' must be first."
        }
      }
    }
  }
}
</i18n>
