<template>
  <el-select
    v-cancel-read-only
    :key="key"
    :loading="loading"
    :placeholder="placeholder"
    filterable
    remote
    clearable
    :multiple="multiple"
    :remote-method="load"
    v-bind:value="data"
    @input="output"
    :disabled="disabled"
    @change="selected = true"
  >
    <el-option-group :label="$t('ls.countries.popular')" v-if="selected">
      <el-option
        v-for="(item, key) in optionsPopular"
        :key="key"
        :label="item.name"
        :value="code ? item.alpha2 : simple ? item.name : JSON.stringify(item)"
      />
    </el-option-group>
    <el-option-group v-if="options && options.length" :label="$t('ls.countries.other')">
      <el-option
        v-for="(item, key) in options"
        :key="key"
        :label="item.name"
        :value="code ? item.alpha2 : simple ? item.name : JSON.stringify(item)"
      />
    </el-option-group>
    <el-option-group v-else :label="$t('ls.countries.search')"/>    
  </el-select>
</template>

<style lang="scss">
  .notice {
    color: #999;
    padding: 10px 0;
    font-size: 0.9em;
  }
</style>

<script>
  export default {
    name: 'LsCountries',    
    props: {
      placeholder: String,
      value: String | Object,
      autofocus: Boolean,
      limit: {
        type: Array,
        default: () => []
      },
      popular: {
        type: Array,
        default: () => null
      },
      simple: {
        type: Boolean,
        default: false
      },
      multiple: {
        type: Boolean,
        default: false
      },
      code: {
        type: Boolean,
        default: true
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        key: 0,
        loading: false,
        options: [],
        optionsPopular: [],
        data: null,
        selected: true
      };
    },

    watch: { 
      //language switched - reload data again
      "$i18n.locale": async function() {
        this.load();
        this.loadPopular();
      },
      value() {
        this.reload();
      }
    },

    created() {
      this.loadPopular();
      this.reload();
    },

    methods: {
      loadPopular() {
        if(this.$i18n.locale == 'cz' && this.popular === null) {
          this.optionsPopular = [
            {"id":203,"name":"Česko","alpha2":"cz","alpha3":"cze"},
            {"id":703,"name":"Slovensko","alpha2":"sk","alpha3":"svk"},
          ];
        }
        else {
          this.loading = true;
          this.$api.post('/external-api/countries2/' + this.$i18n.locale, {
            codes: this.popular
          })
          .then(res => res.data)
          .then(res => {
            this.loading = false;
            this.optionsPopular = Object.values(res);
          });
        }        
      },

      async reload() {
        if(!this.value) {
          this.data = null;
          return;
        }

        if(this.value) {
          if(!this.code && !this.simple) {
            if(this.options.length == 0 || this.options.findIndex(x => x.alpha3 === this.value.alpha3) === -1) {
              this.options.push(this.value);
            }
          }
          if(this.code) {
            if(
              this.optionsPopular.findIndex(x => x.alpha2 === this.value) === -1 && (
                this.options.length == 0 || 
                this.options.findIndex(x => x.alpha2 === this.value) === -1
              )
            ) {
              await this.load(this.value, true);
              await this.$nextTick();
              this.key++;
            }
          }
        }

        this.data = (this.code || this.simple) ? this.value : JSON.stringify(this.value);
      },

      output(item) {
        if(item){
          var out = (this.code || this.simple) ? item : JSON.parse(item);
          this.$emit('input', out);
          this.$emit('change', out);
        }
        else{
          this.$emit('input', null);
          this.$emit('change', null);
        }
      },

      load(query, code = false) {
        if(!query) {
          this.selected = true;
          this.options = [];
          this.output();
          return;
        }

        this.selected = false;
        this.loading = true;
        return this.$api.post('/external-api/countries2/' + this.$i18n.locale, { 
          codes: code ? [query] : undefined,
          data: code ? undefined : query 
        })
        .then(res => {
          this.options = Object.values(res.data);

          //apply restrictions
          if(this.limit?.length)
            this.options = this.options.filter( x => this.limit.includes(x.alpha3) );

          if(!(this.code || this.simple) && this.value) {
            this.output(JSON.stringify(this.options.find(x => x.alpha3 == this.value.alpha3)));
          }

          this.loading = false;
        });
      }
    }
  };
</script>

<i18n>
  {
    "cz": {
      "ls": {
        "countries": {
          "popular": "Populární",
          "other": "Ostatní",
          "search": "Začněte psát pro vyhledávání..."
        }
      }
    },
    "sk": {
      "ls": {
        "countries": {
          "popular": "Populárne",
          "other": "Ostatné",
          "search": "Začnite písať pre vyhľadávanie..."
        }
      }
    },
    "en": {
      "ls": {
        "countries": {
          "popular": "Popular",
          "other": "Other",
          "search": "Start typing to search..."
        }
      }
    },
    "pl": {
      "ls": {
        "countries": {
          "popular": "Popularne",
          "other": "Inne"
        }
      }
    },
    "hu": {
      "ls": {
        "countries": {
          "popular": "Népszerű",
          "other": "Egyéb"
        }
      }
    },
    "ru": {
      "ls": {
        "countries": {
          "popular": "Популярный",
          "other": "Другой"
        }
      }
    }
  }
</i18n>
