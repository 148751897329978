/**
 * Common javascript for proculus app and external forms
 */
window._ = require('lodash');

import Vue from "vue"
window.Vue = Vue;

//date formating options
window.moment = require('moment');

import ResizeObserver from 'resize-observer-polyfill';
if(!window.ResizeObserver)
  window.ResizeObserver = ResizeObserver;

import PerformanceObserver from '@fastly/performance-observer-polyfill';
if(!window.PerformanceObserver)
  window.PerformanceObserver = PerformanceObserver;

import ElementUI from 'element-ui';

Vue.use(ElementUI);

import axios from 'axios';
Vue.prototype.$http = axios;
Vue.prototype.$api = axios.create();

//default context - will be overwritten in most cases
Vue.prototype.$context = {
  access:"external"
}

/**
 * Vue Helper "Plugins"
 */
import stringHelpers from './plugins/stringHelpers';
Vue.prototype.$stringHelpers = stringHelpers;

import globalHelpers from './plugins/globalHelpers';
Vue.prototype.$globalHelpers = globalHelpers;
import customRules from './plugins/customRules';

Vue.prototype.$customRules = customRules;
import orAPI from './plugins/orAPI';

import singleCase from './plugins/singleCase';
Vue.prototype.$singleCase = singleCase;

Vue.prototype.$join = (component, slot, name) => {
    return (h, page) => h(component, [ h(slot, { slot: name }), [ page ] ] )
}

Vue.prototype.$orAPI = orAPI;

//el-row add default gutter
Vue.component("ElRow").extendOptions.props.gutter.default = 20;

//el-datepicker override defaults
Vue.component("ElDatePicker").extendOptions.props = {
  ...Vue.component("ElDatePicker").extendOptions.props,
  format: { default: "d.M.yyyy" },
  "value-format": { default: "yyyy-MM-dd" },
  "picker-options": { default: () => { return { firstDayOfWeek: 1 } } },
}

//error handling
require("./errors");

//vue i18n translation support
require("./i18n");

//vue JS mixins
require("./mixinsCommon");

/**
 * Vue components
 */
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

import horizontalScroll from 'el-table-horizontal-scroll'
Vue.use(horizontalScroll)

import VueCurrencyInput from 'vue-currency-input'
Vue.use(VueCurrencyInput, {
  globalOptions: {
    currency: null,
    locale: 'cs-CZ',
    precision: { min: 0, max: 2 },
    distractionFree: {
      hideNegligibleDecimalDigits: true,
      hideCurrencySymbol: false,
      hideGroupingSymbol: false
    }
  }
});

/**
 * Libraries
 */

//datatable
import VueDataTables from 'vue-data-tables';
Vue.use(VueDataTables);

//draggable
import draggable from 'vuedraggable'
Vue.component('draggable', draggable);

//signature
import VueSignature from "vue-signature-pad";
Vue.use(VueSignature);

/**
 * Custom components
 */
//Override default el-form-item component
import lsFormItem from './components/lsFormItem.vue';
Vue.component('el-form-item-legacy', Vue.component('ElFormItem'));
Vue.component('el-form-item', lsFormItem);

import lsInputNumber from './components/lsInputNumber.vue';
Vue.component('ls-input-number', lsInputNumber);

import lsFormLang from './components/lsFormLang.vue';
Vue.component('ls-form-lang', lsFormLang);

import lsInputPhone from './components/lsInputPhone/lsInputPhone.vue';
Vue.component('ls-input-phone', lsInputPhone);

import lsInputEmail from './components/lsInputEmail.vue';
Vue.component('ls-input-email', lsInputEmail);

import lsInputRc from './components/lsInputRc.vue';
Vue.component('ls-input-rc', lsInputRc);

import lsInputId from './components/lsInputId.vue';
Vue.component('ls-input-id', lsInputId);

import lsLoop from './components/lsLoop.vue';
Vue.component('ls-loop', lsLoop);

import LsCard from './components/ls-card.vue';
Vue.component('ls-card', LsCard);

import LsSinglecaseSend from './components/ls-singlecase-send.vue';
Vue.component('ls-singlecase-send', LsSinglecaseSend);

import LsSinglecaseAutocomplete from './components/ls-singlecase-autocomplete.vue';
Vue.component('ls-singlecase-autocomplete', LsSinglecaseAutocomplete);

/**
 * Signature components
 */
import lsSignaturepad from './components/signature/lsSignaturePad.vue';
Vue.component('ls-signature', lsSignaturepad);

//signing process (bezrealitky)
import lsSignatureExternalForm from './components/signature/lsSignatureExternalForm.vue';
Vue.component('ls-signature-external-form', lsSignatureExternalForm);

//upload document for signature (realman)
import lsSignature from './components/signature/upload/lsSignature.vue';
Vue.component('ls-signature-create', lsSignature);

//KYC
import lsNewKyc from './components/checks/kyc/new-kyc.vue';
Vue.component('ls-new-kyc', lsNewKyc);

import lsKycOverview from './components/checks/kyc/attempt/overview.vue';
Vue.component('ls-kyc-overview', lsKycOverview);

//PEP
import lsPep from './components/checks/pep/lsPep.vue';
Vue.component('ls-pep', lsPep);

import lsPepProtocol from './components/checks/pep/lsPepProtocol.vue';
Vue.component('ls-pep-protocol', lsPepProtocol);

import lsPepLegacyProtocol from './components/checks/pep/lsPepLegacyProtocol.vue';
Vue.component('ls-pep-legacy-protocol', lsPepLegacyProtocol);

import lsCopy from './components/lsCopy.vue';
Vue.component('ls-copy', lsCopy);


//file & image upload
import lsUpload from './components/lsUpload.vue';
Vue.component('ls-upload', lsUpload);

//image upload
import lsImageUpload from './components/lsImageUpload.vue';
Vue.component('ls-imageupload', lsImageUpload);

//file preview
import lsPreview from './components/preview/lsPreview.vue';
Vue.component('ls-preview', lsPreview);

import lsViewerNative from './components/preview/lsViewerNative.vue';
Vue.component('ls-viewer-native', lsViewerNative);

import lsViewerMicrosoft from './components/preview/lsViewerMicrosoft.vue';
Vue.component('ls-viewer-microsoft', lsViewerMicrosoft);

import lsViewerGoogle from './components/preview/lsViewerGoogle.vue';
Vue.component('ls-viewer-google', lsViewerGoogle);


//obce & katastralni uzemi CR
import lsAddress from './components/lsAddress.vue';
import lsObce from './components/lsObce.vue';
import lsCountries from './components/lsCountries.vue';
import lsCountries2 from './components/lsCountries2.vue';
import lsCountries3 from './components/lsCountries3/lsCountries3.vue';
import lsKUs from './components/lsKUs.vue';
Vue.component('ls-address', lsAddress);
Vue.component('ls-obce', lsObce);
Vue.component('ls-katastralni-uzemi', lsKUs);
Vue.component('ls-countries', lsCountries);
Vue.component('ls-countries2', lsCountries2);
Vue.component('ls-countries3', lsCountries3);

import lsMunicipalities from './components/lsMunicipalities/lsMunicipalities.vue';
Vue.component('ls-municipalities', lsMunicipalities);

import lsHelp from './components/lsHelp.vue';
Vue.component('ls-help', lsHelp);

//obchodni rejstrik
import lsPO from './components/lsPo/lsPO.vue';
Vue.component('ls-po', lsPO);

//person (strany/osoby)
import lsPersons from './components/lsPersons/lsPersons.vue';
Vue.component('ls-persons', lsPersons);

import lsPersonsOverview from './components/lsPersons/lsPersonsOverview.vue';
Vue.component('ls-persons-overview', lsPersonsOverview);

//ls button
import lsButton from './components/lsButton.vue';
Vue.component('ls-button', lsButton);

//ls autocomplete
import lsAutocomplete from './components/lsAutocomplete.vue';
Vue.component('ls-autocomplete', lsAutocomplete);

import lsAutocomplete2 from './components/lsAutocomplete2.vue';
Vue.component('ls-autocomplete2', lsAutocomplete2);

import lsSelect from './components/lsSelect.vue';
Vue.component('ls-select', lsSelect);

import lsSelect2 from './components/lsSelect2.vue';
Vue.component('ls-select2', lsSelect2);

import lsSelectColor from './components/ls-select-color.vue';
Vue.component('ls-select-color', lsSelectColor);

import lsContacts from './components/lsContacts.vue';
Vue.component('ls-contacts', lsContacts);

/**
 * Vue Filters
 */
Vue.filter('formatDate', function (value) {
  if (value)
    return moment(String(value)).format('D. M. YYYY')
});
Vue.filter('formatDateTime', function (value) {
  if (value)
    return moment(String(value)).format('D. M. YYYY HH:mm:ss')
});
Vue.filter('formatDateTimeMin', function (value) {
  if (value)
    return moment(String(value)).format('D. M. YYYY HH:mm')
});
Vue.filter('formatAddress', function (value) {
  if (value)
    return [value.WHOLE_ADDRESS, value.country].join(', ');
});
Vue.filter('pluralize', function (value, one, more, many) {
  if (value == 1) //1
    return value + ' ' + one;
  else if (value > 1 && value <= 4) //2-4
    return value + ' ' + more;
  else //5 - inf && 0
    return value + ' ' + many;
});
Vue.directive('number', {
  update: function (el, _, vnode) {
    let element = el.getElementsByTagName("INPUT")[0];
    var output;
    if (element.value === "")
      output = null;
    else
      output = parseFloat(element.value);
    if (isNaN(output))
      output = element.value;
    vnode.componentInstance.$emit('input', output);
  }
});

Vue.directive('cancel-read-only', function(el) {
  const input = el.querySelector('.el-input__inner');
  input.removeAttribute('readonly');
});
