<template>
  <el-form-item :label="label" :prop="cProp" :rules="[required ? rl.required : {}, rl.rc]">
    <el-input :value="value" @input="value => $emit('input', value)" @change="calculateBirth()" v-mask="['######/####', '######/###']" :placeholder="placeholder" />
  </el-form-item>
</template>

<script>
export default {
  emits: ['update'],
  props: {
    value: {
      type: String,
      default: ''
    },
    prop: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    label: {
      type: String,
      default: function(){
        return this.$t('ls.inputRc.label')
      }
    },
    placeholder: {
      type: String,
      default: function(){
        return this.$t('ls.inputRc.placeholder')
      }
    }
  },
  computed: {
    cProp(){
      return this.prop ? this.prop : this.$vnode.data.model.expression.replace("doc.", "")
    }
  },
  methods: {
    calculateBirth() {
      let calculation = this.$stringHelpers.parseRC(this.value);

      this.$emit('update', calculation);
      this.$emit('birthdate', calculation?.date);
      this.$emit('gender', calculation?.gender);
    },
  }
}
</script>

<i18n>
{
  "cz": {
    "ls": {
      "inputRc": {
        "placeholder": "Celé rodné číslo",
        "label": "Rodné číslo"
      }
    }
  },
  "sk": {
    "ls": {
      "inputRc": {
        "placeholder": "Celé rodné číslo",
        "label": "Rodné číslo"
      }
    }
  },
  "en": {
    "ls": {
      "inputRc": {
        "placeholder": "Full personal identification number",
        "label": "Personal identification number"
      }
    }
  }
}
</i18n>
