<template>
  <ls-card class="person" ref="wrapper" :hide-label="hideLabel" :hide-logo="hideLogo">
    <div slot="title">{{name}}</div>

    <template v-if="model !== null">
      <el-card 
        v-for="(person, index) in model" :key="index+person.type"
        v-bind:class="{ 'person-card': index > 0, 'hide-border': !multiple, 'settings-card': multiple }" 
        :body-style="{ padding: multiple ? '20px' : '0px' }"
      >
        <div slot="header" class="strana-header" v-if="multiple">
          <div class="h4">{{name}} {{$t('ls.persons.no')}} {{index+1}}</div>

          <el-tooltip v-if="!cLock" effect="dark" :content="$t('ls.persons.deletePerson')" placement="left">
            <el-button class="remove-person-button" type="text" icon="el-icon-close" @click="removeArrayItem(model, index)"></el-button>
          </el-tooltip>
        </div>

        <el-form-item v-if="types.length > 1">
          <el-tooltip :disabled="!isContactSelected(person)" effect="dark" :content="$t('ls.persons.types.warning')" placement="top">
            <el-radio-group :disabled="cLock || isContactSelected(person)" v-model="person.type" size="mini">
              <el-radio-button v-if="hasType.FO" label="FO">{{ $t('ls.persons.types.individual') }}</el-radio-button>
              <el-radio-button v-if="hasType.SJM" label="SJM">{{ $t('ls.persons.types.spouses') }}</el-radio-button>
              <el-radio-button v-if="hasType.OSVC" label="OSVC">{{ $t('ls.persons.types.selfEmployed') }}</el-radio-button>
              <el-radio-button v-if="hasType.PO" label="PO">{{ $t('ls.persons.types.company') }}</el-radio-button>
              <el-radio-button v-if="hasType.TOWN" label="TOWN">{{ $t('ls.persons.types.publicLegalEntity') }}</el-radio-button>
            </el-radio-group>
          </el-tooltip>
        </el-form-item>

        <!-- SJM - 2 persons under one -->
        <div v-if="person.type === 'SJM'">
          <!-- MCP selector -->
          <el-form-item v-if="sjm_mcp" :rules="[rl.required]" :prop="handleProp(index, 'sjm.mcp')">
            <el-radio-group v-model="person.sjm.mcp" size="mini">
              <el-radio-button :label="false">{{$t('ls.persons.commonProperty')}}</el-radio-button>
              <el-radio-button :label="true">{{$t('ls.persons.foreignSpouses')}}</el-radio-button>
            </el-radio-group>
          </el-form-item>

          <el-collapse v-bind:value="['1', '2']" style="margin-bottom: 20px">
            <el-collapse-item name="1">
              <span slot="title" class="h4">{{$t('ls.persons.husband')}} <i class="fas fa-male" /></span>
              <Person 
                v-bind="personDefaults"
                :bank="sjm_bank == 'individual' ? personDefaults.bank : false"
                v-model="model[index].sjm.p1" 
                :prop="handleProp(index, 'sjm.p1')"
              />

              <template v-if="!contacts || model[index].sjm.p1.contact_id">
                <Zastupce 
                  v-if="representative" 
                  v-model="model[index].sjm.p1" 
                  v-bind="zastupceDefaults"
                  :sjm_shared="model[index].sjm.shared_representative"
                  :prop="handleProp(index, 'sjm.p1.representative')"
                />

                <!-- slot for custom data - SJM partner 1 only -->
                <LsFormItemProvider :root="'x.'+cProp" :index="index+'.sjm.p1'">
                  <slot v-bind:ix="index" v-bind:index="index" name="sjm" v-bind:px="'p1'" v-bind:partner="person.sjm.p1"/>
                </LsFormItemProvider>
              </template>
            </el-collapse-item>
            <el-collapse-item name="2">
              <span slot="title" class="h4">{{$t('ls.persons.wife')}} <i class="fas fa-female" /></span>               
              <Person 
                v-bind="personDefaults"
                :bank="sjm_bank == 'individual' ? personDefaults.bank : false"
                v-model="model[index].sjm.p2" 
                :prop="handleProp(index, 'sjm.p2')"
              />

              <template v-if="!contacts || model[index].sjm.p2.contact_id">
                <Zastupce 
                  v-if="representative" 
                  v-model="model[index].sjm.p2" 
                  v-bind="zastupceDefaults"
                  :sjm_p1="model[index].sjm.p1.representative"
                  :sjm_shared="model[index].sjm.shared_representative"
                  @sjm_shared="model[index].sjm.shared_representative = $event"
                  :prop="handleProp(index, 'sjm.p2.representative')"
                />

                <!-- slot for custom data - SJM partner 2 only -->
                <LsFormItemProvider :root="'x.'+cProp" :index="index+'.sjm.p2'">
                  <slot v-bind:ix="index" v-bind:index="index" name="sjm" v-bind:px="'p2'" v-bind:partner="person.sjm.p2"/>
                </LsFormItemProvider>
              </template>
            </el-collapse-item>
          </el-collapse>

          <!-- shared bank account for SJM -->
          <el-row>
            <el-col :md="8" :sm="12">
              <el-form-item v-if="sjm_bank == 'shared'" :rules="[req.bank ? rl.required : {}, rl.cu]" :prop="handleProp(index, 'bank')" :label="$t('ls.persons.sharedAccount')">
                <el-input :placeholder="$t('ls.persons.accountNumber')" v-model="person.bank"/>
              </el-form-item>
            </el-col>
          </el-row>
        </div>

        <!-- all other person types -->
        <div v-else>
          <Person 
            v-bind="personDefaults"
            v-model="model[index]" 
            :prop="handleProp(index)"
          />

          <Zastupce 
            v-if="representative && (!contacts || model[index].contact_id)" 
            v-model="model[index]" 
            v-bind="zastupceDefaults"
            :prop="handleProp(index, 'representative')"
          />
        </div>

        <!-- slot for custom data - common -->
        <!-- root is extended with 'x.', because it is stripped deep inside ls-form-item -->
        <LsFormItemProvider v-if="!contacts || model[index].contact_id || model[index].sjm.p1.contact_id || model[index].sjm.p2.contact_id" :root="'x.'+cProp" :index="index">
          <slot v-bind:ix="index" v-bind:index="index" name="all" v-bind:person="person"/>
        </LsFormItemProvider>
      </el-card>
    </template>

    <div class="person-button" v-if="multiple && !cLock">
      <el-button @click="add" icon="el-icon-plus">{{$t('ls.persons.addAnotherPerson')}}</el-button>
    </div>
  </ls-card>
</template>

<style lang="scss" scoped>
  .strana-header::v-deep {
    display: flex;
    align-items: center;
    gap: 1em;
    text-transform: uppercase;

    .h3, .h4 {
      margin: 0;
    }

    .remove-person-button {
      float: initial;
      margin-left: auto;
    }
  }

  .radio-card {
    margin-bottom: 0;
    .el-radio-button {
      margin-bottom: 0;
    }
  }

  .hide-border {
    border: none;
    padding: 0;
  }

</style>

<script>
  import Person from './_person';
  import Zastupce from './_zastupce';

  export default {
    name: 'LsPersons',
    inject: ['features'],
    components: {
      Person,
      Zastupce,
      LsFormItemProvider: {
        props: ['root', 'index'],
        provide() {
          return {
            lsFormItem: {
              index: this.index,
              root: this.root,
            }
          }
        },
        template: `<div><slot/></div>`
      }      
    },
    props: {
      value: {
        type: Array
      },
      //contacts switch
      contacts: { 
        type: Boolean,
        default: function() {
          return this.features?.contacts;
        }
      },
      //name (eg. Seller)
      name: { 
        type: String,
        required: true
      },
      //prop for validators
      prop: { 
        type: String,
        default: undefined
      },
      //inject persons instead of contacts
      injected: {
        type: Array,
        default: () => []
      },

      hideLabel: {
        type: Boolean,
        default: false
      },
      hideLogo: {
        type: Boolean,
        default: false
      },

      /**
       *  Component Settings
       */
      //person types
      types: {
        type: Array,
        default: () => ["FO", "SJM", "PO"]
      },
      //lock adding person and changing person type (used with katastr)
      lock: {
        type: null,
        default: false
      },
      //if false, can be used as single-person (with output still in array for easy upgradebility)
      multiple: {
        type: Boolean,
        default: true
      },
      //extend JSON of person with additional data - used in combination with slots
      extend: {
        type: Object
      }, 
      
      lang: {
        type: Array,
        default: () => ["cz"]
      },

      //use alpha3 country code instead of country name
      countriesCode: {
        type: Boolean,
        default: true
      },

      /**
       * Person settings
       */
      //občianstvo
      person_citizenship: {
        type: Boolean,
        default: false
      },
      //ID document
      person_identityDocument: {
        type: Boolean,
        default: false
      },
      //ID document details
      person_identityDocumentDetails: {
        type: Boolean,
        default: false
      },
      //rodinný status
      person_familyStatus: {
        type: Boolean,
        default: false
      },
      //person identification - allowed values: 'rc', 'birthdate', 'combined', false
      person_identification: {
        default: 'rc',
      },
      //person identification default type for combined identification - allowed values: 'rc', 'birthdate'
      person_identificationDefault: {
        default: 'rc',
      },
      //person gender - only when identification is birthdate or disabled
      person_gender: {
        type: Boolean,
        default: false
      },
      //override address fields label for person (eg. "Bydliště" instead of "Trvalé bydliště")
      person_addressLabel: {
        type: String,
        default: null
      },
      //SJM only - Manžele cizího práva
      sjm_mcp: {
        type: Boolean,
        default: false
      },
      sjm_bank: {
        default: 'individual'
      },
      //Company only - dič
      or_dic: {
        type: Boolean,
        default: true
      },
      //Company - allow other countries
      or_countries: {
        type: Boolean,
        default: false
      },
      //Company - show fields for statutory authority
      or_statutory: {
        type: Boolean,
        default: true
      },
      //Company - show field for court
      or_court: {
        type: Boolean,
        default: true
      },
      //Company - show field for case number
      or_case_number: {
        type: Boolean,
        default: true
      },
      //allow primary address
      address: {
        type: Boolean,
        default: true
      },
      //allow secondary address
      address2: {
        type: Boolean,
        default: false
      },
      email: {
        type: Boolean,
        default: false
      },
      phone: {
        type: Boolean,
        default: false
      },
      statutory_email: {
        type: Boolean,
        default: false
      },
      statutory_phone: {
        type: Boolean,
        default: false
      },

      statutory_customCheckbox: {
        type: Boolean,
        default: false
      },
      statutory_customCheckboxIcon: {
        type: String,
        default: null
      },
      statutory_customCheckboxTooltip: {
        type: String,
        default: null
      },

      //bank account
      bank: {
        type: Boolean,
        default: false
      },
      //person representative (zástupce)
      representative: {
        type: Boolean,
        default: false
      },
      
      //what fields are required? Expects object, see this.req
      required: { 
        type: Object
      },
    },

    data () {
      return {
        model: this.value,

        //prop provided or use "default"
        cProp: this.prop ?? this.$vnode.data.model.expression.replace("doc.", ""),
        cLock: false,
        
        //default settings for required fields
        req: { 
          name: true,
          address: true,
          email: true,
          phone: true,
          bank: false,
          company: {
            registration_country: true,
            cid: true,
            vatid: false,
            court: true,
            case_number: true,
            statutory: true,
            statutory_email: false,
            statutory_phone: false
          },
          person: {
            identification: true,
            nationality: true,
            passport: true,
          },
          representative: {
            function: true,
            email: true,
            phone: true
          }
        },
      }
    },

    created() {
      this.cLock = typeof this.lock === "function" ? this.lock() : this.lock;
    },

    mounted() {
      if(this.model === null) {
        this.model = [];
        this.add();
      }

      if(this.required)
        this.req = _.merge(this.req, this.required);
    },

    watch: {
      model:{
        handler(val) {
          this.$emit('input', val);
        },
        deep: true
      }
    },

    computed: {
      hasType() {
        var out = {};
        ['FO', 'SJM', 'PO', 'OSVC', 'TOWN'].forEach(x => {
          out[x] = this.types.includes(x);
        });
        return out;
      },

      /**
       * v-bind parameters to zastupce subcomponent
       */
      zastupceDefaults() {
        return {
          "contacts": this.contacts,
          "injected": this.injected,
          "person_identification": this.person_identification,
          "person_identification-default": this.person_identificationDefault,
          "person_gender": this.person_gender,
          "person_addressLabel": this.person_addressLabel,
          "address": this.address,
          "or_dic": this.or_dic,
          "or_countries": this.or_countries,
          "or_statutory": this.or_statutory,
          "or_court": this.or_court,
          "or_case_number": this.or_case_number,
          "email": this.email,
          "phone": this.phone,
          "statutory_email": this.statutory_email,
          "statutory_phone": this.statutory_phone,
          "statutory_custom-checkbox": this.statutory_customCheckbox,
          "statutory_custom-checkbox-icon": this.statutory_customCheckboxIcon,
          "statutory_custom-checkbox-tooltip": this.statutory_customCheckboxTooltip,
          "required": this.req,
          "lang": this.lang,
          "countries-code": this.countriesCode,
        }
      },

      /**
       * v-bind parameters to person subcomponent
       */
      personDefaults() {
        return {
          ...this.zastupceDefaults,
          "lock": this.cLock,
          "person_citizenship": this.person_citizenship,
          "person_identity-document": this.person_identityDocument,
          "person_identity-document-details": this.person_identityDocumentDetails,
          "person_family-status": this.person_familyStatus,
          "address2": this.address2,
          "bank": this.bank,
          "representative": this.representative,
        }
      }
    },

    methods: {
      /**
       * Helper for correct components :prop settings (for form validators)
       */
      handleProp(index, prop = null) {
        return this.cProp+'.'+index + (prop ? ('.'+prop) : '');
      },

      /**
       * Add new person
       */
      add() {
        this.model.push(this.getEmpty(this.extend));
      },

      /**
       * Empty person object
       * Could contain SJM as 2 partners
       */
      getEmpty(extend = null) {
        var obj = { 
          id: null, 
          ...Person.methods.getEmpty(),
          type: this?.types?.[0] || 'FO',
          sjm: {
            mcp: false,
            shared_representative: false,
            p1: Person.methods.getEmpty("ženatý"),
            p2: Person.methods.getEmpty("vdaná"),
          }
        };

        //clear SJM json
        delete obj.sjm.p1.or;
        delete obj.sjm.p2.or;

        //additional data extension
        if(extend) {
          for(const [key, value] of Object.entries(extend)) {
            obj[key] = value;
            obj.sjm.p1[key] = value;
            obj.sjm.p2[key] = value;
          }
        }

        return obj;
      },

      isContactSelected(person) {
        return Boolean(this.contacts && (person.contact_id || person.sjm?.p1?.contact_id || person.sjm?.p2?.contact_id));
      },
    }
  }
</script>

<i18n>
{
  "en": {
    "ls": {
      "persons": {   
        "no": "no.",
        "deletePerson": "Delete person",
        "commonProperty": "Joint marital property (SJM)",
        "foreignSpouses": "Spouses of foreign law (MCP)",
        "husband": "Husband",
        "wife": "Wife",
        "sharedAccount": "Joint account number of spouses",
        "accountNumber": "Account number",
        "addAnotherPerson": "Add another person",
        "types": {
          "warning": "Person type cannot be changed if contact is selected",
          "individual": "Individual",
          "spouses": "Spouses",
          "selfEmployed": "Self-employed",
          "company": "Company",
          "publicLegalEntity": "Public legal entity"
        }
      }
    }
  },
  "cz": {
    "ls": {
      "persons": {   
        "no": "č.",
        "deletePerson": "Smazat osobu",
        "commonProperty": "Společné jmění manželů (SJM)",
        "foreignSpouses": "Manželé cizího práva (MCP)",
        "husband": "Manžel",
        "wife": "Manželka",
        "sharedAccount": "Společné číslo účtu manželů",
        "accountNumber": "Číslo účtu",
        "addAnotherPerson": "Přidat další osobu",
        "types": {
          "warning": "Typ osoby nelze změnit, pokud je vybrán kontakt",
          "individual": "Fyzická osoba",
          "spouses": "Manželé",
          "selfEmployed": "OSVČ",
          "company": "Společnost",
          "publicLegalEntity": "Veřejnoprávní subjekt"
        }
      }
    }
  }
}
</i18n>
