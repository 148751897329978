<template>
<div>
  <el-autocomplete
    class="full-width"
    v-model="model"
    :disabled="disabled"
    clearable
    :fetch-suggestions="querySearch(defaults[suggestions])"
    :placeholder="placeholder"
    @select="select"
    @change="change">
    <el-button :slot="button" @click="manage">
      <i v-if="loading" class="el-icon-loading"></i>
      <i v-else class="el-icon-s-operation"></i>
    </el-button>
    <slot v-if="button == 'append'" name="prepend" slot="prepend"></slot>
    <slot v-else name="append" slot="append"></slot>
  </el-autocomplete>

  <el-dialog
    custom-class="lsDialog" 
    append-to-body
    title="Úprava číselníku" 
    :visible.sync="dialogFormVisible" 
    @close="store" 
    :before-close="validate">
    <el-form :model="defaults" ref="enum">
      <draggable v-model="defaults[suggestions]" handle=".fa-bars" v-loading="loading">
        <template v-if="!multi">
          <el-form-item class="c_data" v-for="(item, index) in defaults[suggestions]" :prop="suggestions+'.'+index+'.value'" :key="index" :rules="[rl.required]">
            <el-input v-model="item.value" :key="index">
              <template slot="prepend"><i class="fas fa-bars" style="cursor: grab;"></i></template>
              <el-button type="danger" slot="append" @click="removeArrayItem(defaults[suggestions], index)"><i class="el-icon-delete"></i></el-button>
            </el-input>
          </el-form-item>
        </template>
        <template v-else>
          <el-row :gutter="20" v-for="(item, index) in defaults[suggestions]" :key="index">
            <div v-if="index" class="autoSpacing hidden-sm-and-up"></div>
            <el-col :sm="colWidth">
              <el-form-item class="c_data" :prop="suggestions+'.'+index+'.value'" :rules="[rl.required]">
                <el-input v-model="item.value" :placeholder="placeholder">
                  <template slot="prepend"><i class="fas fa-bars" style="cursor: grab;"></i></template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="colWidth" v-for="(mx, ix) in multi" :key="index+'-'+ix">
              <el-form-item class="c_data" :prop="suggestions+'.'+index+'.'+ix" :rules="mx.validators ? mx.validators : []">
                <el-input v-model="item[ix]" :placeholder="mx.placeholder ? mx.placeholder : null">
                  <el-button v-if="isLast(ix)" type="danger" slot="append" @click="removeArrayItem(defaults[suggestions], index)"><i class="el-icon-delete"></i></el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </draggable>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button style="float:left" type="primary" @click="newItem">
        <i class="el-icon-plus"></i> Přidat položku
      </el-button>
      <el-button @click="validate(() => dialogFormVisible = false)">Zavřít</el-button>
    </span>
  </el-dialog>

</div>
</template>

<style type="text/css">
  .c_data {
    margin-bottom:20px !important;
  }

  .autoSpacing {
    margin-bottom: 20px;
  }
</style>

<script>
  export default {
    name: 'LsAutocomplete',
    props: {
      value: null,
      placeholder: null,
      disabled: {
        type: Boolean,
        default: false
      },
      defaults: {
        type: Object,
        required: true
      },
      //variable name (eg. bank, or orderId)
      suggestions: {
        type: String,
        required: true
      }, 
      button: {
        type: String,
        default: "append"
      },
      //client or user
      storage: {
        type: String,
        default: "user"
      },
      //handle multiple input, eg:
      //{ email: { validators: [rl.required, rl.email], placeholder: 'E-mail' } }
      multi: {
        type: Object,
        default: null
      }
    },
    data () {
      return {
      	model: this.value,
        dialogFormVisible: false,
        loading: true,
        multiObj: {}
      }
    },
    computed: {
      colWidth() {
        if(!this.multi)
          return 24;
        return 24/(Object.keys(this.multi).length+1);
      }
    },
    watch: {
      value(val) {
        this.model = this.value;
      },
	    model(val) { 
	      this.$emit('input', val);
	    }
  	},
    created() {
      //handle multiple input
      if(this.multi) {
        Object.keys(this.multi).forEach(el => {
          this.multiObj[el] = '';
        });
      }

      this.loading = true;
      this.$api.get('/proculus-api/getCustomData/' + this.storage + '/' + this.suggestions)
        .then(res => {
          this.loading = false;
          if(res.data || res.data === [])
            this.defaults[this.suggestions] = res.data;
        })
        .catch(error => {
          this.loading = false;
          this.$sentry.captureException(error);
        });
    },
    methods: {
      manage() {
        this.dialogFormVisible = true;
      },

      validate(done) {
        this.$refs['enum'].validate((valid) => {
          if (valid)
            return done();
          this.$message({
            showClose: true,
            message: 'Zkontrolujte zadané údaje.',
            type: 'error'
          });
          return false;
        });        
      },

      store() {
        if(!this.loading) { //store only if not loading
          this.loading = true;
          this.$api.post('/proculus-api/setCustomData/' + this.storage + '/' + this.suggestions, {
            data: this.defaults[this.suggestions],
            }).then(res => {
              this.loading = false;
            }).catch(error => {
              this.loading = false;
              this.$sentry.captureException(error);
            });
        }
      },

      //handle multiple input
      isLast(index) {
        return Object.keys(this.multi)[Object.keys(this.multi).length-1] == index;
      },

      newItem() {
        //handle multiple input
        var obj = {value:''};
        if(this.multiObj)
          obj = _.merge(obj, this.multiObj);

        this.addArrayItem(this.defaults[this.suggestions], obj);
      },
      change(val) {
        this.$emit('change', val);
      },
      select(val) {
        this.$emit('select', val);
      }
    }
  }
</script>
