<template>
  <div>
    <el-form-item v-if="contacts" :prop="handleProp('contact_id')" :rules="required.name ? [rl.required] : []">
      <ContactCard
        v-model="model"
        :disabled="!settings.autosave"
        :lock="lock"
        :type="$globalHelpers.personTypesToContactTypes(model.type)"
        :change-internal="mapContactToPerson"
        :change-external="mapPersonToContact"
        :additional-columns="contactAdditionalColumns"
        @cancel="cancelContact"
        @select="selectContact"
      />
    </el-form-item>

    <div v-if="!contacts || model.contact_id">
    <div style="margin-top:0px; margin-bottom:4px" v-if="!_representative">
      <strong class="h4">
        <template v-if="is.PO">{{$t('ls._person.titles.companyData')}}</template>
        <template v-else-if="is.TOWN">{{$t('ls._person.titles.companyDataTOWN')}}</template>
        <template v-else-if="is.OSVC">{{$t('ls._person.titles.businessData')}}</template>
        <template v-else>{{$t('ls._person.titles.personalData')}}</template>
      </strong>
    </div>

    <el-row v-if="isBusiness && or_countries">
      <el-col :md="12">
        <el-form-item :label="$t('ls._person.labels.registrationCountry')" :prop="handleProp('or.registration_country')" :rules="[required.company.registration_country ? rl.required : {}]">
          <ls-countries2 v-model="model.or.registration_country" :code="countriesCode" :simple="!countriesCode" @change="!model.address.STREET_AND_NUMBER ? model.address.country = $event : null" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row class="person-name">
      <el-col :md="isBusiness || (isPerson && person_identification) ? 12 : 24">
        <el-form-item
          :label="$t((is.PO || is.TOWN) ? 'ls._person.personName.companyName' : 'ls._person.personName.fullName')"
          :prop="handleProp('name')"
          :rules="[required.name ? rl.required : {}]"
        >
          <!-- @TODO: merge with contacts processing? -->
          <template v-if="injected.length">
            <el-autocomplete
              v-model="model.name"
              :disabled="model.or && model.or.loading"
              :placeholder="$t(is.PO ? 'ls._person.personName.companyName' : is.TOWN ? 'ls._person.personName.cityState' : 'ls._person.personName.fullName')"
              :fetch-suggestions="querySearch(injected, 'name')"
              value-key="name"
              @select="data => {
                if(data.type) {
                  let map = {
                    company: 'PO',
                    person: 'FO',
                    self_employed: 'OSVC',
                  };
                  let type = map[data.type];

                  //don't flip TOWN to PO
                  if(model.type != 'TOWN' || type != 'PO') {
                    model.type = type;
                  }
                }

                if(model.or) {
                  model.or.name = data.name;
                  model.or.ic = data.ic;
                  model.or.dic = data.dic;
                }
                model.email = data.email;
                model.phone = data.phone;
                model.bank = data.bank;

                model.person.birthdate = data.birthdate;
                model.person.rc = data.rc;

                if(address) {
                  if(model.address === null) {
                    model.address = {};
                  }

                  model.address.STREET_AND_NUMBER = data.address_street;
                  model.address.CITY = data.address_city;
                  model.address.ZIP = data.address_postal;

                  var zip_city = [data.adress_postal, data.address_city].filter(Boolean).map(s => s.trim()).join(' ');
                  model.address.WHOLE_ADDRESS = [data.address_street, zip_city].filter(Boolean).map(s => s.trim()).join(', ');
                }

                //auto-download data from OR for eligible persons by IC or name
                if(model.type != 'FO') {
                  if(model.or.ic) {
                    searchByIco(model.or);
                  }
                  else {
                    searchByName(model.or);
                  }
                }
              }"
            >
              <el-button v-if="isBusiness && isRegistrationCz" slot="append" :icon="loadingIcon" @click="searchByName(model.or)">
                {{$t('ls._person.common.loadFromCR')}}
              </el-button>
            </el-autocomplete>
          </template>
          <template v-else>
            <el-input
              v-model="model.name"
              :disabled="model.or && model.or.loading"
              :placeholder="$t(is.PO ? 'ls._person.personName.companyName' : is.TOWN ? 'ls._person.personName.cityState' : 'ls._person.personName.fullName')"
            >
              <el-button v-if="isBusiness && isRegistrationCz" slot="append" :icon="loadingIcon" @click="searchByName(model.or)">
                {{$t('ls._person.common.loadFromCR')}}
              </el-button>
            </el-input>
          </template>


        </el-form-item>
      </el-col>

      <el-col :md="12" v-if="isBusiness || (isPerson && person_identification)">
        <div v-if="isBusiness" style="display: flex; gap: 20px; flex-wrap: wrap;">
          <div style="flex-grow: 1; min-width: 250px">
            <el-form-item :label="$t(isRegistrationCz || isRegistrationSk ? 'ls._person.personName.ico' : 'ls._person.personName.registrationNumber')" :prop="handleProp('or.ic')" :rules="[required.company.cid ? rl.required : {}, (isRegistrationCz || isRegistrationSk ? rl.ico : {})]">
              <el-input v-model="model.or.ic" :placeholder="$t(isRegistrationCz || isRegistrationSk ? 'ls._person.personName.ico' : 'ls._person.personName.registrationNumber')" :disabled="model.or && model.or.loading">
                <el-button v-if="isRegistrationCz" slot="append" :icon="loadingIcon" @click="searchByIco(model.or)">
                  {{$t('ls._person.common.loadFromCR')}}
                </el-button>
              </el-input>
            </el-form-item>
          </div>
          <div style="flex-shrink: 0; width: 150px">
            <el-form-item :label="$t(isRegistrationCz || isRegistrationSk ? 'ls._person.personName.vatid' : 'VAT ID')" :prop="handleProp('or.dic')" :rules="[required.company.vatid ? rl.required : {}]" v-if="or_dic">
              <template slot="help">{{$t('ls._person.personName.vatidHelp')}}</template>
              <el-input v-model="model.or.dic" :placeholder="$t(isRegistrationCz || isRegistrationSk ? 'ls._person.personName.vatid' : 'VAT ID')" :disabled="model.or && model.or.loading"/>
            </el-form-item>
          </div>
        </div>
        <PersonIdentification
          v-if="isPerson && person_identification"
          v-model="model.person"
          :prop="handleProp('person')"
          :required="required.person.identification"
          :identification="person_identification"
          :gender="person_gender"
        />
      </el-col>
    </el-row>
    <el-row v-if="_representative">
      <el-col :span="24">
        <ls-form-lang :label="$t('ls._person.representative.function')" :lang="lang" :rules="[required.representative.function ? rl.required : {}]">
          <template #cz="{form}">
            <el-form-item v-bind="form" :prop="handleProp('function')">
              <el-input v-model="model.function" :placeholder="$t('ls._person.representative.function')" />
            </el-form-item>
          </template>

          <template #en="{form}">
            <el-form-item v-bind="form" :prop="handleProp('functionEN')">
              <el-input v-model="model.functionEN" :placeholder="$t('ls._person.representative.functionEnglish')" />
            </el-form-item>
          </template>
        </ls-form-lang>
      </el-col>
    </el-row>

    <!-- Personal data -->
    <template v-if="isPerson">
      <!-- DOKLAD -->
      <el-row v-if="person_identityDocument || person_citizenship || (!is.SJM && person_familyStatus)">
        <el-col v-if="person_citizenship" :sm="6" :md="8">
          <el-form-item :label="$t('ls._person.personalData.citizenship')" :prop="handleProp('person.nationality')" :rules="[required.person.nationality ? rl.required : {}]">
            <ls-countries2 v-model="model.person.nationality" :code="countriesCode" />
          </el-form-item>
        </el-col>

        <el-col v-if="person_identityDocument" :sm="18" :md="16">
          <ls-input-id
            :prop="handleProp('person.passport')"
            v-model="model.person.passport"
            :country="!person_citizenship ? 'cz' : model.person.nationality ? (countriesCode ? model.person.nationality : model.person.nationality.alpha2) : undefined"
            :required="required.person.passport"
          />
        </el-col>

        <el-col v-if="!is.SJM && person_familyStatus" :sm="8">
          <el-form-item :label="$t('ls._person.personalData.familyStatus')">
            <el-select v-model="model.person.family" filterable allow-create :placeholder="$t('ls._person.personalData.selectOrWrite')">
              <template v-if="!model.person.gender || model.person.gender == 'muž'">
                <el-option value="svobodný"/>
                <el-option value="ženatý"/>
                <el-option value="rozvedený"/>
                <el-option value="vdovec"/>
              </template>
              <template v-if="!model.person.gender || model.person.gender == 'žena'">
                <el-option value="svobodná"/>
                <el-option value="vdaná"/>
                <el-option value="rozvedená"/>
                <el-option value="vdova"/>
              </template>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <template v-if="person_identityDocument && person_identityDocumentDetails">
        <el-row>
          <el-col :sm="12">
            <el-form-item :label="$t('ls._person.personalData.issuerCountry')">
              <ls-countries2 v-model="model.person.id_issuer_country" :code="countriesCode" :simple="!countriesCode" />
            </el-form-item>
          </el-col>
          <el-col :sm="12">
            <el-form-item :label="$t('ls._person.personalData.issuerAuthority')">
              <el-input v-model="model.person.id_issuer_authority" :placeholder="$t('ls._person.personalData.issuerAuthority')"/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :sm="12">
            <el-form-item :label="$t('ls._person.personalData.issueDate')" :prop="handleProp('person.id_issued_date')" :rules="[
              { validator: $customRules.fromToday, message: 'Datum musí být v minulosti' },
            ]">
              <el-date-picker :placeholder="$t('ls._person.chooseDate')" v-model="model.person.id_issued_date"/>
            </el-form-item>
          </el-col>
          <el-col :sm="12">
            <el-form-item :label="$t('ls._person.personalData.expiryDate')" :prop="handleProp('person.id_expiration_date')" :rules="[
              { validator: $customRules.tillToday, message: 'Průkazu vypršela platnost' },
            ]">
              <el-date-picker :placeholder="$t('ls._person.chooseDate')" v-model="model.person.id_expiration_date"/>
            </el-form-item>
          </el-col>
        </el-row>
      </template>
    </template>

    <!-- ADDRESS -->
    <template v-if="address">
      <span class="el-form-item" :class="{'is-required': required.address}"><label class="el-form-item__label">
        <template v-if="isBusiness">{{$t('ls._person.personalData.registeredOffice')}}</template>
        <template v-else-if="person_addressLabel">{{$t('ls._person.personalData.permanentResidence')}}</template>
        <template v-else>{{$t('ls._person.personalData.permanentResidence')}}</template>
      </label></span>
      <el-row>
        <ls-address
          :disabled="model.or && model.or.loading"
          :countries-code="countriesCode"
          :countries-simple="!countriesCode"
          :labels="false"
          v-model="model.address"
          :required="required.address"
          :prop="handleProp('address')"
        />
      </el-row>
    </template>

    <!-- Address 2 -->
    <div v-if="address2">
      <label class="el-form-item__label">
        <el-checkbox :value="model.address2 !== null" @input="handleAddress2">{{$t('ls._person.personalData.correspondenceAddress')}}</el-checkbox>
      </label>
      <el-row v-if="model.address2">
        <ls-address
          :countries-code="countriesCode"
          :countries-simple="!countriesCode"
          :labels="false"
          v-model="model.address2"
          required
          :prop="handleProp('address2')"
        />
      </el-row>
      <template v-else><br><br></template>
    </div>

    <!-- Company data -->
    <template v-if="is.PO">
      <el-row>
        <el-col :sm="12" v-if="or_court">
          <ls-form-lang :label="$t('ls._person.companyData.court')" :lang="lang" :rules="[isRegistrationCz || isRegistrationSk ? (required.company.court ? rl.required : {}) : {}]">
            <template #cz="{form}">
              <el-form-item v-bind="form" :prop="handleProp('or.Soud')">
                <el-input v-model="model.or.Soud" :placeholder="$t('ls._person.companyData.courtPlaceholder')" :disabled="model.or && model.or.loading" />
              </el-form-item>
            </template>

            <template #en="{form}">
              <el-form-item v-bind="form" :prop="handleProp('or.SoudEN')">
                <el-input v-model="model.or.SoudEN" :placeholder="$t('ls._person.companyData.courtPlaceholder')" :disabled="model.or && model.or.loading" />
              </el-form-item>
            </template>
          </ls-form-lang>
        </el-col>
        <el-col :sm="12" v-if="or_case_number">
          <el-form-item :label="$t('ls._person.companyData.caseNumber')" :prop="handleProp('or.Oddil_vlozka')" :rules="[isRegistrationCz || isRegistrationSk ? (required.company.case_number ? rl.required : {}) : {}]">
            <el-input v-model="model.or.Oddil_vlozka" :placeholder="$t('ls._person.companyData.caseNumberPlaceholder')" :disabled="model.or && model.or.loading"/>
          </el-form-item>
        </el-col>
      </el-row>
    </template>
    <Statutory
      v-if="(is.PO || is.TOWN) && or_statutory"
      v-model="model.or"
      :prop="handleProp('or')"
      :required="required.company.statutory"
      :lang="lang"
      :statutar-email="statutory_email ? (required.company.statutory_email && !model.representative ? 'required' : true) : undefined"
      :statutar-phone="statutory_phone ? (required.company.statutory_phone && !model.representative ? 'required' : true) : undefined"
      :custom-checkbox="statutory_customCheckbox"
      :custom-checkbox-icon="statutory_customCheckboxIcon"
      :custom-checkbox-tooltip="statutory_customCheckboxTooltip"
    />

    <!-- CONTACT -->
    <template v-if="email || phone || bank || _representative">
      <strong class="h4" v-if="!_representative">{{$t('ls._person.contactDetails')}}</strong>
      <el-row>
        <el-col :sm="12" :md="8" v-if="email">
          <ls-input-email
            :label="$t('ls._person.contactData.email')"
            :prop="handleProp('email')"
            :required="_representative ? required.representative.email : required.email"
            v-model="model.email"
          />
        </el-col>

        <el-col :sm="12" :md="8" v-if="phone">
          <ls-input-phone
            :prop="handleProp('phone')"
            :required="_representative ? required.representative.phone : required.phone"
            v-model="model.phone"
            :ip="false"
          />
        </el-col>

        <el-col :sm="12" :md="8" v-if="bank">
          <el-form-item
            :label="$t('ls._person.contactData.accountNumber')"
            :prop="handleProp('bank')"
            :rules="[required.bank ? rl.required : {}, rl.cu]"
          >
            <el-input :placeholder="$t('ls._person.contactData.accountNumber')" v-model="model.bank"/>
          </el-form-item>
        </el-col>

        <el-col :sm="12" :md="8" v-if="_representative">
          <el-form-item :label="$t('ls._person.contactData.attorneySignatureDate')">
            <el-date-picker :placeholder="$t('ls._person.chooseDate')" v-model="model.attorney" />
          </el-form-item>
        </el-col>
      </el-row>
    </template>
  </div>
  </div>
</template>

<style lang="scss" scoped>
.person-name::v-deep {
  .el-input-group__prepend,
  .el-input-group__append {
    padding: 0 10px;
  }
}
</style>

<script>
  import ContactCard from '../Contact/ContactCard.vue';
  import Statutory from '../lsPo/_statutar.vue';
  import PersonIdentification from './_personIdent.vue';

  export default {
    inject: ['settings'],
    components: {
      Statutory,
      PersonIdentification,
      ContactCard,
    },
    props: {
      contacts: {
        type: Boolean,
        default: false
      },
      value: {
        type: Object,
        required: true
      },
      prop: {
        type: String,
        required: true
      },
      lock: {
        type: Boolean,
        default: false
      },
      lang: {
        type: Array,
        default: () => ['cz']
      },
      injected: {
        type: Array,
        default: () => []
      },
      countriesCode: true,
      person_identification: null,
      person_identificationDefault: null,
      person_identityDocument: false,
      person_identityDocumentDetails: false,
      person_familyStatus: false,
      person_citizenship: false,
      person_gender: undefined,
      person_addressLabel: undefined,
      or_dic: undefined,
      or_countries: undefined,
      or_statutory: undefined,
      or_court: undefined,
      or_case_number: undefined,
      address: null,
      address2: null,
      representative: null,
      required: null,
      email: undefined,
      phone: undefined,
      statutory_email: false,
      statutory_phone: false,
      statutory_customCheckbox: false,
      statutory_customCheckboxIcon: null,
      statutory_customCheckboxTooltip: null,
      bank: undefined,

      //private prop - use person as representative
      _representative: false
    },
    data() {
      return {
        model: this.value,
        oldOr: null
      };
    },
    watch: {
      /**
       * ARES
       * sync 'or' subproperty 'name' with toplevel
       */
      "model.name"(val) {
        if(this.model.or)
          this.model.or.name = val;
      },

      /**
       * ARES
       * pull fetched data to toplevel
       */
      "model.or": {
        handler(val) {
          if(val.loading != this.oldOr?.loading || val.address != this.oldOr?.address) {
            this.orWatcherHandler();
          }
          this.oldOr = Object.assign({}, val);
        },
        deep: true
      },
      model:{
        handler(val) {
          this.$emit('input', val);
        },
        deep: true
      },
    },
    computed: {
      /**
       * Person type helpers
       */
      is() {
        var out = {};
        ['FO', 'SJM', 'PO', 'OSVC', 'TOWN'].forEach(x => {
          out[x] = this.model.type === x;
        });
        return out;
      },
      isPerson() {
        return ['FO', 'SJM'].includes(this.model.type);
      },
      isBusiness() {
        return ['OSVC', 'PO', 'TOWN'].includes(this.model.type);
      },
      isRegistrationSk() {
        return this.or_countries && (this.model.or.registration_country === 'sk' || this.model.or.registration_country === 'Slovensko');
      },
      isRegistrationCz() { //default is CZ
        return !this.or_countries || this.model.or.registration_country === 'cz' || this.model.or.registration_country === 'Česko' || this.model.or.registration_country === undefined;
      },

      /**
       * Return array of eligible contact types for contactCard
       */
      contactTypes() {
        if(!this.contacts)
          return [];
        if(this.model.type == 'SJM')
          return ['FO'];
        if(this.model.type == 'TOWN')
          return ['PO'];
        return [this.model.type];
      },

      /**
       * ARES
       * loading icon
       */
      loadingIcon() {
        return this.model.or.loading ? 'el-icon-loading' : 'el-icon-search';
      },

      /**
       * Additional columns for contactCard
       */
      contactAdditionalColumns() {
        return [
        ...((this.is.FO || this.is.OSVC || this.is.SJM) ? [
          ...(this.person_gender ? [
            'gender'
          ] : []),
          ...(this.person_identification == 'combined' ? [
            'rc', 'birthdate'
          ] : []),
          ...(this.person_identification == 'birthdate' ? [
            'birthdate'
          ] : []),
          ...(this.person_identification == 'rc' ? [
            'rc'
          ] : []),
          ...(this.person_citizenship ? [
            'nationality',
          ] : []),
          ...(this.person_identityDocument ? [
            'id_number',
            'id_type',
            ...(this.person_identityDocumentDetails ? [
              'id_expiration_date',
              'id_issued_date',
              'id_issuer_country',
              'id_issuer_authority',
            ] : []),
          ] : []),
        ] : []),
        ...((this.or_countries && (this.is.PO || this.is.TOWN)) ? [
          'registration_country',
        ] : []),
        ...((this.is.OSVC || this.is.PO || this.is.TOWN) ? [
          'ic',
          'dic',
        ] : []),
        ...(this.address ? [
                'address_street',
                'address_city',
                'address_postal',
                'address_country',
        ] : []),
        ...(this.email ? [
                'email',
        ] : []),
        ...(this.phone ? [
                'phone',
        ] : []),
        ...(this.bank ? [
                'bank_account',
        ] : []),
      ];
      }
    },

    created() {
      //set default identification type
      if(!this.model.person.identificationType) {
        if(this.person_identification == 'combined') {
          this.model.person.identificationType = this.person_identificationDefault;
        }
        else {
          this.model.person.identificationType = this.person_identification;
        }
      }
    },

    methods: {
      handleProp(prop) {
        return this.prop + '.' + prop;
      },

      handleAddress2(val) {
        this.model.address2 = val ? this.$globalHelpers.getEmptyAddress(this.countriesCode ? 'cz' : 'Česko') : null;
      },

      /**
       * single person object
       */
      getEmpty(family = null) {
        return {
          contact_id: null,
          name: null,
          type: family ? 'SJM' : null,

          //contact
          address: null,
          address2: null,
          email: null,
          phone: null,
          bank: null,

          //personal data
          person: {
            identificationType: null,
            birthdate: null,
            rc: null,
            nationality: null,
            passport: null,
            family: family,
            gender: null,
            id_number: null,
            id_type: null,
            id_issuer_country: null,
            id_issuer_authority: null,
            id_issued_date: null,
            id_expiration_date: null,
          },

          //obchodni rejstrik
          or: {
            name: null,
            ic: null,
            dic: null,
            Oddil_vlozka: null,
            Soud: null,
            registration_country: this.countriesCode ? 'cz' : 'Česko',
            address: null,
            addressDetail: null,
            loading: false,
            Statutarni_organ: [{
              name: null,
              function: null,
              email: null,
              phone: null,
              bank: null
            }],
            Statutarni_organ_text: null,
          },

          //zastupce
          representative: null,

          //representative person data
          attorney: null,
          function: null,
          functionEN: null,
        }
      },

      mapContactToPerson(model, contact) {
        //force set identification type
        if(this.person_identification == 'birthdate' || this.person_identification == 'rc') {
          model.person.identificationType = this.person_identification;
        }

        this.$globalHelpers.contactToPersonMapper(model, this.countriesCode, this.address)(contact);

        if(this.person_identification == 'combined' && this.person_identificationDefault == 'birthdate' && !model.person.rc) {
          model.person.identificationType = 'birthdate';
        }
      },

      mapPersonToContact(model, contact) {
        return this.$globalHelpers.personToContactMapper(model, this.countriesCode, this.address)(contact);
      },

      /**
       * clear contact data when canceling
       */
      cancelContact() {
        this.model = {
          ...this.model,
          ...this.getEmpty(this.model.person.family),
          type: this.model.type,
        };
      },

      /**
       * auto load data from ARES when selecting PO
       */
      selectContact() {
        if(this.model.type == 'PO' || this.model.type == 'TOWN') {
          this.model.or.ic ? this.searchByIco(this.model.or) : this.searchByName(this.model.or);
        }
      },

      /**
       * ARES search functions
       */
      searchByIco(model) {
        if(this.is.PO)
          return this.orSearchByIco(model);
        return this.orSearchByIcoBasic(model);
      },
      searchByName(model) {
        if(this.is.PO)
          return this.orSearchByName(model);
        return this.orSearchByNameBasic(model);
      },
      orWatcherHandler() {
        if(!this.model.or.loading && this.model.or.name) {
          this.model.name = this.model.or.name;

          //convert ARES address to ls-address
          if(this.address) {
            if(this.model.or.address && this.model.or.addressDetail) {
              this.$set(this.model, 'address', this.$stringHelpers.aresToAddress(this.model.or, this.countriesCode));
            this.model.or.address = this.model.or.addressDetail = null;
            }
          }
        }
      }

    },
  };
</script>


<i18n>
{
  "en": {
    "ls": {
      "_person": {
        "titles": {
          "companyData": "Company Data",
          "companyDataTOWN": "Entity Data",
          "businessData": "Entrepreneur Data",
          "personalData": "Personal Data"
        },
        "labels": {
          "registrationCountry": "Country of Registration"
        },
        "representative": {
          "function": "Representative Function",
          "functionEnglish": "Representative Function in English"
        },
        "personName": {
          "companyName": "Company Name",
          "fullName": "Full Name including titles",
          "cityState": "State, region, city, town, or public-law entity",
          "ico": "CIN",
          "registrationNumber": "Registration Number",
          "vatid": "VAT ID",
          "vatidHelp": "If the VAT ID field remains empty, the VAT ID will not be included in the output document."
        },
        "common": {
          "loadFromCR": "Load from Company Register"
        },
        "contactDetails": "Contact Details",
        "chooseDate": "Choose Date",
        "personalData": {
          "citizenship": "Citizenship",
          "issuerCountry": "Issuing Country",
          "issuerAuthority": "Issuing Authority",
          "issueDate": "Issue Date",
          "expiryDate": "Expiry Date",
          "familyStatus": "Family Status",
          "registeredOffice": "Registered Office",
          "permanentResidence": "Permanent Residence",
          "correspondenceAddress": "Correspondence Address",
          "selectOrWrite": "Select or Write"
        },
        "companyData": {
          "court": "Registered at",
          "courtPlaceholder": "Court registration",
          "caseNumber": "Under File No.",
          "caseNumberPlaceholder": "File number"
        },
        "contactData": {
          "email": "Contact email",
          "accountNumber": "Account number",
          "attorneySignatureDate": "Attorney signature date"
        }
      }
    }
  },
  "cz": {
    "ls": {
      "_person": {
        "titles": {
          "companyData": "Obchodní údaje společnosti",
          "companyDataTOWN": "Obchodní údaje",
          "businessData": "Údaje podnikatele",
          "personalData": "Osobní údaje"
        },
        "labels": {
          "registrationCountry": "Země registrace"
        },
        "representative": {
          "function": "Funkce zástupce",
          "functionEnglish": "Funkce zástupce v angličtině"
        },
        "personName": {
          "companyName": "Název subjektu",
          "fullName": "Celé jméno včetně titulů",
          "cityState": "Stát, kraj, obec, městská část či veřejnoprávní PO",
          "ico": "IČ",
          "registrationNumber": "Registrační číslo",
          "vatid": "DIČ",
          "vatidHelp": "V případě, že pole pro zadání DIČ zůstane prázdné, nebude DIČ ve výstupním dokumentu vůbec uvedeno."
        },
        "common": {
          "loadFromCR": "Načíst z OR"
        },
        "contactDetails": "Kontaktní údaje",
        "chooseDate": "Vyberte datum",
        "personalData": {
          "citizenship": "Občanství",
          "issuerCountry": "Stát vydávající doklad",
          "issuerAuthority": "Orgán vydávající doklad",
          "issueDate": "Datum vydání průkazu",
          "expiryDate": "Konec platnosti",
          "familyStatus": "Rodinný stav",
          "registeredOffice": "Sídlo",
          "permanentResidence": "Trvalé bydliště",
          "correspondenceAddress": "Korespondenční adresa",
          "selectOrWrite": "Vyberte anebo napište"
        },
        "companyData": {
          "court": "Vedená u",
          "courtPlaceholder": "Rejstříkový soud",
          "caseNumber": "Pod sp. zn.",
          "caseNumberPlaceholder": "Spisová značka"
        },
        "contactData": {
          "email": "Kontaktní e-mail",
          "accountNumber": "Číslo účtu",
          "attorneySignatureDate": "Datum podpisu plné moci"
        }
      }
    }
  }
}
</i18n>
