<template>
  <el-select
    v-cancel-read-only
    :key="$i18n.locale"
    :placeholder="placeholder"
    filterable
    clearable
    :multiple="multiple"
    v-bind:value="value"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    :disabled="disabled"
  >
    <template v-if="popular && popular.length">
      <el-option-group :label="$t('ls.countries.popular')">
        <el-option
          v-for="(item, key) in countries.filter(x => popular.includes(x.a2))"
          :key="key"
          :label="item[$i18n.locale]"
          :value="item.a2"
        />
      </el-option-group>
      <el-option-group :label="$t('ls.countries.other')">
        <el-option
          v-for="(item, key) in countries.filter(x => !popular.includes(x.a2))"
          :key="key"
          :label="item[$i18n.locale]"
          :value="item.a2"
        />
      </el-option-group>
    </template>
    <template v-else>
      <el-option
        v-for="(item, key) in countries"
        :key="key"
        :label="item[$i18n.locale]"
        :value="item.a2"
      />
    </template>
  </el-select>
</template>

<script>
  import countries from './countries';

  export default {
    name: 'LsCountries',    
    props: {
      placeholder: String,
      value: Array | String,
      popular: {
        type: Array | Boolean,
        default: () => ['cz', 'sk']
      },
      multiple: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        countries: countries,
      };
    }
  };
</script>

<i18n>
  {
    "cz": {
      "ls": {
        "countries": {
          "popular": "Populární",
          "other": "Ostatní"
        }
      }
    },
    "sk": {
      "ls": {
        "countries": {
          "popular": "Populárne",
          "other": "Ostatné"
        }
      }
    },
    "en": {
      "ls": {
        "countries": {
          "popular": "Popular",
          "other": "Other"
        }
      }
    },
    "pl": {
      "ls": {
        "countries": {
          "popular": "Popularne",
          "other": "Inne"
        }
      }
    },
    "hu": {
      "ls": {
        "countries": {
          "popular": "Népszerű",
          "other": "Egyéb"
        }
      }
    },
    "ru": {
      "ls": {
        "countries": {
          "popular": "Популярный",
          "other": "Другой"
        }
      }
    }
  }
</i18n>
