<template>
  <el-autocomplete
    :fetch-suggestions="querySearch(municipalities[country], 'name')"
    value-key="name"
    clearable
    :placeholder="$t('ls.municipalities.placeholder')"
    v-bind:value="value"
    @input="$emit('input', $event)"
    @clear="$emit('input', null)"
    :disabled="disabled"
  ></el-autocomplete>
</template>

<script>
import sk from "./sk.json";
export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
    country: {
      type: String,
      default: "cz",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      municipalities: {
        sk: sk,
        cz: [],
      },
    };
  }
};
</script>

<i18n>
  {
    "cz": {
      "ls": {
        "municipalities": {
          "placeholder": "Název obce"
        }
      }
    },
    "sk": {
      "ls": {
        "municipalities": {
          "placeholder": "Názov obce"
        }
      }
    },
    "en": {
      "ls": {
        "municipalities": {
          "placeholder": "Enter municipality name"
        }
      }
    }
  }
</i18n>