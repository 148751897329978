<template>
  <el-popover :popper-class="wide ? 'ls-help_popper ls-help_popper-wide' : 'ls-help_popper'" placement="top-start" trigger="hover">
    <div class="ls-help">
      <slot></slot>
    </div>
    <i :class="icon" slot="reference"></i>
  </el-popover>
</template>

<style lang="scss">
.ls-help_popper {
  max-width: 90%;
}

@media (min-width: 600px) {
  .ls-help_popper {
    max-width: 500px;
  }
}

@media (min-width: 900px) {
  .ls-help_popper-wide {
    max-width: 800px;
  }
}

.ls-help {
  max-height: 90vh;
  overflow: scroll;  
  text-align: justify;
  word-break: keep-all;
}
</style>

<script>
  export default {
    name: 'LsHelp',

    props: {
      icon: {
        type: String,
        default: 'el-icon-question'
      },
      wide: {
        type: Boolean,
        default: false
      }
    },
  }
</script>
