<template>
    <div v-loading="loading" :element-loading-text="loadingMessage">
        <div v-if="step === 'decision'" style="text-align: center">
            <el-alert type="info" :closable="false" style="margin-bottom: 2rem" size="large" :title="
                $t('We recommend that you verify your identity on your mobile phone.') + ' ' +
                $t('If you wish to continue on your current device, select the Continue button on that device.')
                + ' (' + $t('we require a camera for ID photos and facial verification') + ')'
            "/>

            <div style="display: flex; flex-direction: column; align-items: center;">
                <el-button name="kyc-device" @click="showRedirectOptions" style="margin-top: 2rem; margin-bottom: 2rem">{{ $t('Redirect to another device') }}</el-button>
                <el-divider>{{ $t('or') }}</el-divider>
                <el-button name="kyc-start" @click="continueOnDevice" style="margin-top: 2rem">{{ $t('Continue on this device') }}</el-button>
            </div>
        </div>

        <template v-if="step === 'redirect-options'">
            <redirect-options
                :kyc-attempt-uuid="kycAttemptUuid"
                @close="hideRedirectOptions"
            />
        </template>

        <template v-if="step === 'process'">
            <kyc-attempt-process
                :kyc-attempt-uuid="kycAttemptUuid"
                :require-additional-document="requireAdditionalDocument"
                @submit="pollKycAttemptStatus(true)"
                @close="hideRedirectOptions"
            />
        </template>
    </div>
</template>

<script type="text/javascript">
import RedirectOptions from '@/components/checks/kyc/redirect-options.vue'
import KycAttemptProcess from '@/components/checks/kyc/attempt/process.vue'

export default {
    components: {
        RedirectOptions,
        KycAttemptProcess,
    },
    props: {
        existingKycUuid: {
            type: String,
            required: false,
            default: null,
        },
        documentExternalUuid: {
            type: String,
            required: false,
            default: null,
            // Edge case for when used in external document
        },
        requireAdditionalDocument: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    data() {
        return {
            kycUuid: this.existingKycUuid,
            kycAttemptUuid: null,
            pollInterval: null,
            loadingMessage: null,

            step: 'decision', // decision, redirect-options, process
            loading: false,
        }
    },
    methods: {
        createKyc() {
            return this.$api.post('/external-api/' + this.$context.client.slug + '/kyc/store', {
                document_external_uuid: this.documentExternalUuid,
                additional_document_required: this.requireAdditionalDocument,
             })
            .then(res => this.kycUuid = res.data.uuid)
            .catch(() => this.$message.error({ message: 'Nepodařilo se založit kontrolu', duration: 5000 }))
        },

        createKycAttempt() {
            return this.$api.post('/external-api/kyc-attempts/store/' + this.kycUuid)
                .then(res => this.kycAttemptUuid = res.data.uuid)
                .catch(() => this.$message.error({ message: 'Nepodařilo se založit pokus o kontrolu', duration: 5000 }))
        },

        pollKycAttemptStatus(isAfterSubmit = false) {
            if (isAfterSubmit) {
                this.loading = true
                this.loadingMessage = 'Probíhá analýza dokladů, tento proces může trvat i několik minut'
                this.$emit('submit')
            }

            this.pollInterval = setInterval(async () => {
                const res = await this.$api.get('/external-api/kyc-attempts/' + this.kycAttemptUuid + '/result')

                if (res.data.redirected) {
                    this.loadingMessage = 'Čekáme na výsledek ověření z druhého zařízení...'
                    this.loading = true
                }

                if (res.data.status !== 'CREATED') {
                    clearInterval(this.pollInterval)
                    this.$emit('completed', res.data.status)
                    this.$emit('finished', res.data)
                    this.loading = false
                    this.loadingMessage = null
                }
            }, 3000);
        },

        showRedirectOptions() {
            this.pollKycAttemptStatus()
            this.step = 'redirect-options'
        },

        hideRedirectOptions() {
            clearInterval(this.pollInterval)
            this.step = 'decision'
        },

        continueOnDevice() {
            window.scrollTo(0, 0)
            this.step = 'process'
        },

        async init() {
            this.loading = true
            /*************** Temporary solution, it is only needed when used in external documents. **********/
            if (this.documentExternalUuid !== null && this.existingKycUuid === null) {
                await this.createKyc()
            }
            /**************************************************************************************************/

            await this.createKycAttempt()

            if (this.isMobile.any) {
                this.continueOnDevice();
            }

            this.loading = false
        }
    },
    created() {
        this.init();
    },
    beforeDestroy() {
        clearInterval(this.pollInterval)
    }
}
</script>

<i18n>
    {
        "cz": {
            "We recommend that you verify your identity on your mobile phone.": "Ověření identity doporučujeme provést na svém mobilním telefonu.",
            "If you wish to continue on your current device, select the Continue button on that device.": "V případě, že si přejete pokračovat na aktuálním zařízení, zvolte tlačítko \"Pokračovat na tomto zařízení\".",
            "we require a camera for ID photos and facial verification": "Vyžadujeme kameru pro fotky dokladů a ověření obličeje.",
            "Redirect to another device": "Přesměrovat na jiné zařízení",
            "Continue on this device": "Pokračovat na tomto zařízení",
            "or": "nebo",
            "If you wish to verify your identity on another device, retrieve the QR code below or have the SMS link sent to you": "Pokud si přejete provést ověření identity na jiném zařízení, načtěte QR kód níže nebo si nechte poslat SMS odkaz",
            "Back": "Zpět",
            "Please select the type of document we will use to verify your identity, take a photo of the necessary pages and verify your face": "Prosím, vyberte typ dokladu, pomocí kterého ověříme Vaši identitu, vyfoťte potřebné strany a ověřte svůj obličej",
            "Select document type": "Vyberte typ dokladu",
            "Identity card": "Občanský průkaz",
            "Driving license": "Řidičský průkaz",
            "Passport": "Pas",
            "Face verification and selfie": "Kontrola obličeje a selfie",
            "Confirm identification": "Potvrdit identifikaci",
            "To confirm identification, you must upload the document and check the face": "K potvrzení identifikace je nutné nahrát doklad a zkontrolovat obličej"
        },
        "en": {
            "We recommend that you verify your identity on your mobile phone.": "We recommend that you verify your identity on your mobile phone.",
            "If you wish to continue on your current device, select the Continue button on that device.": "If you wish to continue on your current device, select the button \"Continue on this device\".",
            "we require a camera for ID photos and facial verification": "We require a camera for ID photos and facial verification.",
            "Redirect to another device": "Redirect to another device",
            "Continue on this device": "Continue on this device",
            "or": "or",
            "If you wish to verify your identity on another device, retrieve the QR code below or have the SMS link sent to you": "If you wish to verify your identity on another device, retrieve the QR code below or have the SMS link sent to you",
            "Back": "Back",
            "Please select the type of document we will use to verify your identity, take a photo of the necessary pages and verify your face": "Please select the type of document we will use to verify your identity, take a photo of the necessary pages and verify your face",
            "Select document type": "Select document type",
            "Identity card": "Identity card",
            "Driving license": "Driving license",
            "Passport": "Passport",
            "Face verification and selfie": "Face verification and selfie",
            "Confirm identification": "Confirm identification",
            "To confirm identification, you must upload the document and check the face": "To confirm identification, you must upload the document and check the face"
        }        
    }
</i18n>
