<template>
<div>
  <el-autocomplete
    class="full-width"
    v-model="model"
    :disabled="disabled"
    clearable
    :fetch-suggestions="querySearch(dataMerged, valueKey)"
    :value-key="valueKey"
    :placeholder="placeholder"
    @select="select"
    @change="change">
    <el-button :slot="button" @click="dialogFormVisible = true">
      <i v-if="loading" class="el-icon-loading"></i>
      <i v-else class="el-icon-s-operation"></i>
    </el-button>
    <slot v-if="button == 'append'" name="prepend" slot="prepend"></slot>
    <slot v-else name="append" slot="append"></slot>
  </el-autocomplete>

  <el-dialog
    custom-class="lsDialog" 
    append-to-body
    title="Úprava číselníku" 
    :visible.sync="dialogFormVisible" 
    @close="store" 
    :before-close="validate">
    <el-form :model="data" ref="enum">

      <el-tabs v-model="loc" type="card" v-if="storage == 'combined' && !$context.role.superuser">
        <el-tab-pane name="user" label="moje"></el-tab-pane>
        <el-tab-pane name="client" :label="'sdílené - všichni'"></el-tab-pane>
      </el-tabs>

      <draggable v-model="data[loc]" handle=".fa-bars" v-loading="loading">
        <template v-if="!multi">
          <el-form-item class="c_data" v-for="(item, index) in data[loc]" :prop="loc+'.'+index+'.value'" :key="index" :rules="[rl.required]">
            <el-input v-model="item.value" :key="index">
              <template slot="prepend"><i class="fas fa-bars" style="cursor: grab;"></i></template>
              <el-button type="danger" slot="append" @click="removeArrayItem(data[loc], index)"><i class="el-icon-delete"></i></el-button>
            </el-input>
          </el-form-item>
        </template>
        <template v-else>
          <el-row :gutter="20" v-for="(item, index) in data[loc]" :key="index">
            <div v-if="index" class="autoSpacing hidden-sm-and-up"></div>
            <el-col :sm="colWidth">
              <el-form-item class="c_data" :prop="loc+'.'+index+'.value'" :rules="[rl.required]">
                <el-input v-model="item.value" :placeholder="placeholder">
                  <template slot="prepend"><i class="fas fa-bars" style="cursor: grab;"></i></template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="colWidth" v-for="(mx, ix) in multi" :key="index+'-'+ix">
              <el-form-item class="c_data" :prop="loc+'.'+index+'.'+ix" :rules="mx.validators ? mx.validators : []">
                <el-input v-model="item[ix]" :placeholder="mx.placeholder ? mx.placeholder : null">
                  <el-button v-if="isLast(ix)" type="danger" slot="append" @click="removeArrayItem(data[loc], index)"><i class="el-icon-delete"></i></el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </draggable>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button style="float:left" type="primary" @click="newItem(loc)">
        <i class="el-icon-plus"></i> Přidat položku
      </el-button>
      <el-button @click="validate(() => dialogFormVisible = false)">Zavřít</el-button>
    </span>
  </el-dialog>

</div>
</template>

<style type="text/css">
  .c_data {
    margin-bottom:20px !important;
  }

  .autoSpacing {
    margin-bottom: 20px;
  }
</style>

<script>
  export default {
    name: 'LsAutocomplete',
    props: {
      value: null,
      placeholder: null,
      disabled: {
        type: Boolean,
        default: false
      },
      //variable name (eg. bank, or orderId)
      suggestions: {
        type: String,
        required: true
      }, 
      button: {
        type: String,
        default: "append"
      },
      //client, user or combined
      storage: {
        type: String,
        default: "user"
      },
      valueKey: {
        type: String,
      },
      //handle multiple input, eg:
      //{ email: { validators: [rl.required, rl.email], placeholder: 'E-mail' } }
      multi: {
        type: Object,
        default: null
      }
    },
    data () {
      return {
        loc: null,
        data: {
          user: [],
          client: []
        },
        model: this.value,
        dialogFormVisible: false,
        loading: true,
        multiObj: {}
      }
    },
    computed: {
      colWidth() {
        if(!this.multi)
          return 24;
        return 24/(Object.keys(this.multi).length+1);
      },
      dataMerged() {
        if(this.storage == 'combined')
          return this.data["user"].concat(this.data["client"]);
        else
          return this.data[this.storage];
      }
    },
    watch: {
      value(val) {
        this.model = this.value;
      },
      model(val) { 
        this.$emit('input', val);
      }
    },
    created: async function() {
      //handle multiple input
      if(this.multi) {
        Object.keys(this.multi).forEach(el => {
          this.multiObj[el] = '';
        });
      }

      this.loading = true;
      var s1, s2;

      this.loc = this.storage;
      if(this.storage == 'combined')
        this.loc = 'user';

      if(this.storage == 'user' || this.storage == 'combined')
        s1 = this.getData("user");
      if(this.storage == "client" || this.storage == 'combined')
        s2 = this.getData("client");

      await s1;
      await s2;
      this.loading = false;
    },
    methods: {
      validate(done) {
        this.$refs['enum'].validate((valid) => {
          if (valid)
            return done();
          this.$message({
            showClose: true,
            message: 'Zkontrolujte zadané údaje.',
            type: 'error'
          });
          return false;
        });        
      },

      store: async function() {
        if(!this.loading) { //store only if not loading
          this.loading = true;
          var s1, s2, s3;

          if(this.storage == 'user' || this.storage == 'combined')
            s1 = this.setData("user");
          if((this.storage == 'client' || this.storage == 'combined') && !this.$context.role?.superuser)
            s2 = this.setData("client");

          await s1;
          await s2;
          await s3;
          this.loading = false;
        }
      },

      //handle multiple input
      isLast(index) {
        return Object.keys(this.multi)[Object.keys(this.multi).length-1] == index;
      },

      newItem(loc) {
        //handle multiple input
        var obj = {value:''};
        if(this.multiObj)
          obj = _.merge(obj, this.multiObj);

        this.addArrayItem(this.data[loc], obj);
      },
      change(val) {
        this.$emit('change', val);
      },
      select(val) {
        this.$emit('select', val);
      },

      getData(loc) {
        let url = '/proculus-api/getCustomData/'+loc+'/' + this.suggestions;
        if(this.$context.role.superuser && loc == 'client' && this.$context.client?.id) {
          url += '/' + this.$context.client.id;
        }

        return this.$api.get(url)
        .then(res => {
          if(res.data || res.data === []) {
            this.data[loc] = this.data[loc].concat(res.data);
          }
        })
        .catch(this.justFail);
      },

      setData(loc) {
        return this.$api.post('/proculus-api/setCustomData/'+loc+'/' + this.suggestions, {
          data: this.data[loc],
        }).catch(this.justFail);
      },

      justFail(error) {
        this.$sentry.captureException(error);
      }
    }
  }
</script>
