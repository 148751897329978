<template>
    <el-select 
        ref="select"
        :value="value"
        @input="$emit('input', $event)"
        :placeholder="placeholder"
        :disabled="disabled"
        :clearable="clearable"
        :filterable="filterable"
        @change="change"
    >
      <template v-for="item in items">
        <el-option 
            :key="item.value"
            :label="item.placeholder !== undefined ? item.placeholder : item.label" 
            :value="item.value" 
            :style="{ background: item.color }"
        >
            {{ item.label }}
        </el-option>
      </template>
    </el-select>
</template>

<script>
  export default {
    name: 'LsSelectColor',
    props: {
      value: null,
      items: null,
      placeholder: null,
      disabled: {
        type: Boolean,
        default: false
      },
      clearable: {
        type: Boolean,
        default: false
      },
      filterable: {
        type: Boolean,
        default: false
      }
    },
    mounted() {
        this.setColor(this.value);
    },
    watch: {
        value(value) {
            this.setColor(value);
        }
    },
    methods: {
        setColor(value) {
            const item = this.items.find(item => item.value == value);
            this.$refs['select'].$el.querySelectorAll(".el-input__inner")[0].style.background = item?.color ?? null;
        },

        change(value) {
            this.setColor(value);
            this.$emit('change', value);
        }
    }
  }
</script>
